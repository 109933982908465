.PollAnswerResults {
  border-bottom: 1px solid var(--color-borders);
  padding: 0 0.5rem 0.625rem;
  display: flex;
  flex-direction: column-reverse;

  .answer-head {
    display: flex;
    align-items: center;
    font-size: 0.9375rem;
    line-height: 1.3125rem;
    font-weight: 500;
    color: var(--color-text-secondary);
    padding: 1rem 0.75rem 0.5rem 1rem;
    position: sticky;
    top: 0;
    background: var(--color-background);

    @media (max-width: 600px) {
      padding: 0.5rem 0.25rem 0.5rem 0.5rem;
    }
  }

  .answer-percent {
    margin-left: auto;

    &[dir="auto"] {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .poll-voters {
    padding: 0 0.75rem;
    position: relative;
    min-height: 3rem;

    @media (max-width: 600px) {
      padding: 0 0.25rem;
    }

    .Spinner {
      --spinner-size: 1.25rem;
    }
  }

  .chat-item-clickable {
    .ChatInfo .Avatar.size-tiny {
      margin-right: 1.75rem;
    }

    &[dir="rtl"] {
      .ChatInfo .Avatar.size-tiny {
        margin-left: 1.75rem;
        margin-right: 0;
      }
    }
  }

  .ShowMoreButton {
    margin: 0.25rem 0 0 -0.5rem;
    width: calc(100% + 1rem);

    &[dir="rtl"] {
      .icon-down {
        margin-left: 2rem;
        margin-right: 0;
      }
    }
  }

  .icon-down {
    vertical-align: middle;
    margin-right: 2rem;
    font-size: 1.5rem;
  }
}
