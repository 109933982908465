.StickerSetModal {
  .modal-dialog {
    width: 26.25rem;
    max-width: 100%;
  }

  @media (max-width: 600px) {
    .modal-dialog {
      width: 18.875rem;
    }
  }

  .modal-header {
    padding: 0.5rem 1rem;
  }

  .modal-content {
    text-align: center;
    padding: 0;
  }

  .stickers {
    position: relative;
    width: 100%;
    height: 19rem;
    max-height: 50vh;
    overflow-y: auto;
    padding: 0 0.25rem;
    text-align: left;
  }

  .button-wrapper {
    padding: 0.5rem 0;
    border-top: 1px solid var(--color-borders);
    box-shadow: 0 0 2px var(--color-default-shadow);

    button {
      display: inline-block;
    }
  }

  .Loading {
    width: 100%;
    height: 22.8125rem;
    max-height: calc(50vh + 3.8125rem);
  }
}

