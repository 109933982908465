.Radio {
  display: block;
  position: relative;
  padding-left: 3.75rem;
  text-align: left;
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
  flex: 1 1;
  cursor: pointer;
  &.radio--no-mb {
    margin-bottom: 0;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.hidden-widget {
    cursor: default;
    .Radio-main {
      &::before,
      &::after {
        visibility: hidden;
      }
    }
  }

  > input {
    position: absolute;
    z-index: var(--z-below);
    opacity: 0;
  }

  .Radio-main {
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 1.1875rem;
      top: 50%;
      width: 1.125rem;
      height: 1.125rem;
      transform: translateY(-50%);
    }

    &::before {
      box-shadow: 0 0 0 2px var(--color-placeholders);
      border-radius: 50%;
      background-color: var(--color-background);
      opacity: 1;
      transition: border-color 0.1s ease, opacity 0.1s ease;
    }

    &::after {
      left: 1.38rem;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background: var(--color-primary);
      opacity: 0;
      transition: opacity 0.1s ease;
    }

    .label {
      display: block;
      word-break: break-word;
      unicode-bidi: plaintext;
      text-align: left;
    }

    .subLabel {
      display: block;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--color-text-secondary);
      unicode-bidi: plaintext;
    }
  }

  input:checked ~ .Radio-main {
    &::before {
      box-shadow: 0 0 0 2px var(--color-primary);
    }

    &::after {
      opacity: 1;
    }
  }

  &.loading {
    .Radio-main::before,
    .Radio-main::after {
      opacity: 0 !important;
    }

    .Spinner {
      position: absolute;
      left: 1.0625rem;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      animation: fade-in 0.2s ease forwards;
      --spinner-size: 1.25rem;
    }
  }

  &[dir='rtl'] {
    padding-left: 0;
    padding-right: 4.5rem;

    .Radio-main {
      text-align: right;

      &::before {
        left: auto;
        right: 1.0625rem;
      }

      &::after {
        left: auto;
        right: 1.375rem;
      }
    }

    .label,
    .subLabel {
      text-align: right;
    }

    &.loading .Spinner {
      left: auto;
      right: 1.0625rem;
    }
  }
  &.radio-button {
    border: 1px solid var(--color-borders-input);
    border-radius: 1rem;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    .Radio-main {
      position: relative;
      padding-left: 2rem;
      &::before {
        left: 2px;
      }
      &::after {
        left: 0.3rem;
      }
      .label {
        margin: 0;
      }
    }
  }
}

.smaller {
  .Radio .Radio-main {
    &::before {
      width: 1rem;
      height: 1rem;
      left: 1.5rem;
      box-shadow: 0 0 0 1.5px var(--color-placeholders);
    }
    &::after {
      left: 1.6875rem;
      width: 0.625rem;
      height: 0.625rem;
    }
  }
  &.left-top {
    .Radio {
      padding-left: 2.25rem;
      line-height: 1.2;
      margin-bottom: 1rem;
      .Radio-main {
        &::before {
          left: 0.125rem;
          top: 0.75rem;
        }
        &::after {
          left: 0.3125rem;
          top: 0.75rem;
        }
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
