.root {
  display: flex;
  padding: 0.5rem !important;
}

.text {
  display: flex;
  flex-direction: column;
  margin-inline-start: 1.75rem !important;
}

.header {
  display: flex;
}

.description {
  font-size: 0.875rem;
  color: var(--color-text-secondary);
  white-space: pre-wrap;
  line-height: 1.25rem;
  min-height: 2.5rem;
}

.icon {
  min-width: 2rem;
  height: 2rem;
  align-self: center;
  border-radius: 0.625rem;
  background: var(--item-color, #000);
}
