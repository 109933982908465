.root {
  --emoji-size: 2.25rem;
}

.wrapper {
  position: relative;
  height: auto;
  max-height: min(18rem, 100%);
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}
