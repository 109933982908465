.BotCommandMenu {
  .menu-container {
    //width: 100%;
    max-height: 40vh;
    overflow: auto;
    flex-direction: column;
    border-radius: var(--border-radius-messages);
    padding: 0.75rem 0;
  }

  .is-pointer-env & {
    > .backdrop {
      position: absolute;
      top: -1rem;
      left: 0.75rem;
      right: auto;
      width: 5rem;
      height: 4rem;
    }
  }
}
