.CardInput {
  position: relative;

  .input-group.has-right-addon .form-control{
    padding-right: 4rem;
  }

  .right-addon {
    position: absolute;
    top: 0.8125rem;
    right: 1rem;
    z-index: 8;

    img {
      max-width: 2rem;
    }
  }
}
