.Statistics {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  // &__messages,
  // &__public-forwards {

  // }

  &__graph {
    opacity: 1;
    transition: opacity 0.3s ease;

    &:last-of-type {
      margin-bottom: 0;
      border-bottom: none;
    }

    &.hidden {
      opacity: 0;
      margin: 0;
      .lovely-chart--header {
        display: none;
      }
    }
  }

  .lovely-chart {
    &--container {
      font: inherit !important;
      font-size: 13px !important;
    }
    &--header {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      border-radius: 0.75rem;
      padding: 0.5rem 1rem;
      background-color: var(--color-background-secondary);
      transition: all 0.2s cubic-bezier(0.2, 0, 0.2, 1);
      cursor: pointer;
      margin-bottom: 0.75rem;
      &-title {
        flex-grow: 1;
      }
      svg {
        transition: all 0.2s cubic-bezier(0.2, 0, 0.2, 1);
      }
      &:not(.open) {
        .lovely-chart--header-caption {
          [stroke] {
            stroke: var(--color-text);
          }
        }
      }
      &.open {
        background-color: var(--color-background);
        .lovely-chart--header-caption {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
    }

    &--tooltip-balloon {
      left: 3px;
    }
  }

  .lovely-chart--header,
  .lovely-chart--tooltip-title,
  .lovely-chart--tooltip-dataset-value,
  .lovely-chart--percentage-title {
    font-weight: 500 !important;
  }

  .lovely-chart--container-type-pie {
    &.lovely-chart--state-zoomed-in > canvas {
      animation-name: pie-slim-in !important;
    }

    &:not(.lovely-chart--state-zoomed-in) > canvas {
      animation-name: pie-slim-out !important;
    }
  }
  .title-icon {
    &.growthGraph {
      background-color: var(--color-4);
    }
    &.followersGraph {
      background-color: var(--color-6);
    }
    &.muteGraph {
      background-color: var(--color-5);
    }
    &.topHoursGraph {
      background-color: var(--color-green);
    }
    &.viewsBySourceGraph {
      background-color: var(--color-1);
    }
    &.newFollowersBySourceGraph {
      background-color: var(--color-3);
    }
    &.interactionsGraph {
      background-color: var(--color-9);
    }
  }
}

@keyframes pie-slim-in {
  0% {
    clip-path: circle(80% at center calc(50% - 7.5px));
    -webkit-clip-path: circle(80% at center calc(50% - 7.5px));
    transform: rotate(-360deg);
  }

  25% {
    clip-path: circle(40% at center calc(50% - 7.5px));
    -webkit-clip-path: circle(40% at center calc(50% - 7.5px));
    transform: rotate(-360deg);
  }

  75% {
    clip-path: circle(40% at center calc(50% - 7.5px));
    -webkit-clip-path: circle(40% at center calc(50% - 7.5px));
    transform: rotate(0);
  }
}

@keyframes pie-slim-out {
  0% {
    clip-path: circle(40% at center calc(50% - 7.5px));
    -webkit-clip-path: circle(40% at center calc(50% - 7.5px));
    transform: rotate(360deg);
  }

  50% {
    clip-path: circle(40% at center calc(50% - 7.5px));
    -webkit-clip-path: circle(40% at center calc(50% - 7.5px));
    transform: rotate(0);
  }

  75% {
    clip-path: circle(80% at center calc(50% - 7.5px));
    -webkit-clip-path: circle(80% at center calc(50% - 7.5px));
    transform: rotate(0);
  }
}
