.HeaderMenuContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: var(--z-header-menu);

  .Menu {
    position: absolute;
    font-size: 1rem;

    .backdrop {
      z-index: var(--z-header-menu-backdrop);
    }

    .bubble {
      z-index: var(--z-header-menu);
      --offset-y: calc(100% + 0.5rem);
      background: rgba(255, 255, 255, 0.96);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
