@import '../../../styles/mixins';

#NewChat {
  .NewChat-inner {
    height: calc(100% - var(--header-height));
    display: flex;
    flex-direction: column;
  }
}

.NewChat {
  height: 100%;
  overflow: hidden;
  position: relative;

  &-inner {
    height: 100%;
    overflow-x: hidden;
    &.step-1 {
      padding: 0 0 15px 8px;
      .Button.full-width {
        max-width: calc(100% - 4.5rem);
      }
    }
    &.step-2 {
      padding: 0 1.25rem 1rem;
      display: flex;
      flex-direction: column;

      .error {
        color: var(--color-error);
      }

      .note {
        margin-top: -0.5625rem;
        color: var(--color-text-secondary);
      }

      .chat-members-heading {
        color: var(--color-primary);
        font-size: 0.9375rem;
        margin: 1rem 0.25rem 0.375rem;
      }

      .chat-members-list {
        margin: 0 -1.25rem;
        padding: 0 1rem 1rem;

        overflow-x: hidden;
        flex-grow: 1;
      }
    }
  }
  p {
    white-space: pre-line;
  }
  .left-header {
    box-shadow: none;
  }
  .AvatarEditable {
    margin-bottom: 0.5rem;
    .Button {
      display: none;
    }
    label {
      margin-top: 0;
      background: linear-gradient(135deg, #6ca5f9 27.08%, #3a85f3 75.52%);
    }
  }
  .link-block {
    display: flex;
    align-items: center;
    padding: 0.625rem 0;
    .input {
      margin: 0;
    }
  }
  .subscription {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    form {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .center-block {
        justify-content: flex-start;
      }
    }
  }
  .center-block {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fees {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 1rem auto;
    max-width: 320px;
    flex-grow: 1;
    [stroke] {
      stroke: var(--color-primary);
    }
    .link {
      text-decoration: underline;
      color: inherit;
    }
    h4 {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
}

.channel-type-description {
  padding: 1rem 1.125rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  .centered-block {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    flex-grow: 1;
  }
  .Button {
    margin-bottom: 4rem;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.channel-selects {
  .select-dropdown {
    margin-bottom: 0.625rem;
  }
  .CountryCodeInput,
  .select-dropdown {
    .bubble {
      max-height: 14.5rem;
    }
  }
}

.link-block .input-wrapper {
  margin-bottom: 0.5rem;
  // input {
  //   margin-bottom: 20px;
  // }
  // label {
  //   margin-top: 8px;
  // }
}

.input .input-wrapper .padding-label {
  padding-left: 20px;
}

.icon-position {
  position: absolute;
  bottom: 33px;
  left: 9px;
}

.padding-input .input .input-wrapper input {
  padding-left: 31px;
}
