.LeftSearch {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  & > .Transition {
    flex: 1;
    overflow: hidden;
  }

  .TabList {
    z-index: 1;
  }

  .documents-list {
    padding: 0 1.25rem 1.25rem;

    .ListItem {
      padding: 0.625rem 0;
    }
  }

  .section-heading {
    position: relative;
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    margin: 0 0 1rem -1.25rem !important;

    font-weight: 500;
    font-size: 0.9375rem;
    color: var(--color-text-secondary);

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: var(--color-borders);
      left: 0.625rem;
      top: -1px;
    }

    &[dir='rtl'],
    &[dir='auto'] {
      padding-left: 0;
      padding-right: 1.25rem;
      margin: 0 -1.25rem 0 1rem !important;
      text-align: initial;

      &::before {
        left: auto;
        right: 0.625rem;
      }
    }

    &.topic-search-heading {
      margin-left: -1.0625rem !important;
      padding-left: 2.125rem;
    }
  }

  .LeftSearch .search-section .section-heading,
  .RecentContacts .search-section .section-heading {
    margin-left: -0.5rem !important;
    padding-left: 1.5rem;
    width: calc(100% + 0.625rem);
    box-shadow: 0 -1px 0 0 var(--color-borders);

    &::before {
      display: none;
    }
  }

  .WebLink {
    display: flex;
  }

  .content:not(.with-seekline) {
    align-self: center;
    min-width: 0;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .message-date {
    font-size: 0.75rem;
    color: var(--color-text-secondary);
    padding-left: 1rem;
    white-space: nowrap;
  }

  .media-list {
    display: grid;
    padding: 0.5rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 0.25rem;
  }

  .Audio {
    .ProgressSpinner {
      margin: -0.1875rem 0 0 -0.1875rem;
    }
  }

  .ListItem.search-result {
    .ChatInfo {
      .handle {
        unicode-bidi: plaintext;
        color: var(--color-primary);

        &::before {
          content: '@';
          html[lang='ar'] & {
            content: ' ،@';
            margin-inline-end: 0.25rem;
          }
        }

        &:not(:last-child):after {
          content: ', ';
          color: var(--color-text-secondary);

          html[lang='ar'] & {
            content: '';
          }
        }
      }

      &[dir='rtl'] {
        .status {
          text-align: right;

          .handle {
            float: right;
          }
        }
      }
    }

    .topic-item {
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: 1.6875rem;
      font-weight: 500;
      overflow: hidden;

      .topic-icon {
        --custom-emoji-size: 2rem;
        margin-inline-end: 0.25rem !important;
        width: 2rem;
        height: 2rem;
        font-size: 2rem !important;
      }

      .fullName {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ListItem.search-result-message {
    .sender-name {
      color: var(--color-text);

      &::after {
        content: ': ';
        white-space: pre;
      }
    }
  }

  @media (max-width: 600px) {
    .ListItem {
      margin: 0 -0.125rem 0 -0.5rem;
    }
  }

  .search-section {
    padding: 0 0.125rem 0.5rem 0.5rem;

    .section-heading {
      color: var(--color-text-secondary);
      font-size: 0.9375rem;
      font-weight: 500;
      margin-bottom: 0 !important;
      padding-top: 0.875rem;

      .Link {
        float: right;
        color: var(--color-links);
        font-weight: 400;
        margin-right: 1rem;

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      &[dir='rtl'],
      &[dir='auto'] {
        .Link {
          margin-left: 1rem;
          margin-right: 0;
        }
      }
    }

    .Loading {
      height: 4.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .chat-selection {
    padding-top: 0.5rem;
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    box-shadow: inset 0 -1px 0 0 var(--color-borders);
    background-color: var(--color-background);
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;

    scrollbar-color: rgba(0, 0, 0, 0);

    > .PickerSelectedItem {
      flex: 0 0 auto;

      &:last-child {
        margin-right: auto;
      }
    }

    &[dir='rtl'] {
      > .PickerSelectedItem:last-child {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  .NothingFound {
    height: 100%;
    padding: 0.5rem 1rem;
    text-align: center;
  }

  [dir='rtl'] {
    .message-date {
      padding-left: 0;
      padding-right: 1rem;
    }
  }
}
