.EmojiInteractionAnimation {
  --start-x: 0;
  --start-y: 0;
  --scale: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;

  @keyframes show-interaction {
    from {
      transform: translate(0, 0) scale(var(--scale, 0));
    }

    to {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @keyframes show-interaction-reversed {
    from {
      transform: translate(50%, 0) scaleX(-1) scale(var(--scale, 0));
    }

    to {
      transform: translate(100%, -50%) scaleX(-1) scale(1);
    }
  }

  .AnimatedSticker {
    position: absolute;
    top: var(--start-y);
    left: var(--start-x);
    transform: scale(var(--scale), 0);
    transform-origin: left top;
    transition: 0.25s cubic-bezier(0.29, 0.81, 0.27, 0.99) opacity;
  }

  &.reversed .AnimatedSticker {
    transform: scale(var(--scale), 0) scaleX(-1);
  }

  &.playing .AnimatedSticker {
    animation: show-interaction forwards 0.25s cubic-bezier(0.29, 0.81, 0.27, 0.99);
  }

  &.reversed.playing .AnimatedSticker {
    animation: show-interaction-reversed forwards 0.25s cubic-bezier(0.29, 0.81, 0.27, 0.99);
  }

  &.hiding .AnimatedSticker {
    opacity: 0;
  }

  &.reversed.hiding .AnimatedSticker {
    opacity: 0;
  }
}
