.Shipping {
  padding: 0.5rem 1rem;

  form {
    p {
      color: var(--color-text-secondary);
      font-weight: 500;
      margin: 0.5rem 0 2rem;
    }

    .Radio {
      margin-bottom: 2rem;
    }
  }
}
