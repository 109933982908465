.JoinRequest {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-borders);
  }

  &__top {
    display: flex;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.5rem;

    &:hover {
      background-color: var(--color-chat-hover);
    }
  }

  &__user {
    display: flex;
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    margin-left: 1rem;
  }

  &__user-subtitle {
    color: var(--color-text-secondary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__user-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__date {
    color: var(--color-text-secondary);
    font-size: 0.875rem;
    margin-left: 1rem;
    white-space: nowrap;
  }

  &__buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
    gap: 0.5rem;
  }

  &__button {
    width: auto;
    height: auto;
  }
}
