.root {
  :global(.ListItem-button) {
    padding: 0.5rem 0.75rem !important;

    :global(.last-message),
    :global(.status),
    :global(.typing-status) {
      line-height: 1.5rem !important;
    }

    :global(.LastMessageMeta) {
      padding-top: 0;

      margin-top: -0.5rem;
      margin-right: 0 !important;
    }
  }

  :global(.fullName .emoji-small) {
    width: 1rem;
    height: 1rem;
  }

  :global(body.is-ios) &::after {
    left: 0.75rem !important;
  }

  &::after {
    left: 0.75rem !important;
  }
}

.closed-icon {
  color: var(--color-pinned);
  font-size: 0.75rem;
  margin-top: -0.5rem;
  margin-right: 0.25rem;
}

.topic-icon {
  margin-inline-end: 0.25rem;
}
