.ReactionSelector {
  position: absolute;
  height: 3rem;
  background: var(--color-background);
  min-width: 3rem;
  max-width: calc(100% + 1rem);
  z-index: 100;
  border-radius: 0.75rem;
  box-shadow: 0px 1.58333px 3.16667px rgba(0, 0, 0, 0.14),
    0px 0.316667px 5.7px rgba(0, 0, 0, 0.12),
    0px 0.95px 1.58333px var(--color-default-shadow);
  // border-radius: 12px;
  // background: #FFF;
  // box-shadow: 0px 1.583px 3.167px 0px rgba(0, 0, 0, 0.14), 0px 0.317px 5.7px 0px rgba(0, 0, 0, 0.12), 0px 0.95px 1.583px 0px rgba(0, 0, 0, 0.20);
  // left: -0.5rem;
  left: 0;
  top: -3.5rem;

  &__bubble-big {
    border: 0.5rem solid var(--color-background);
    position: absolute;
    display: block;
    content: '';
    right: 1.5rem;
    bottom: -0.5rem;
    width: 1rem;
    height: 1rem;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0 0 50% 50%;
    z-index: -1;
  }

  &__bubble-small {
    position: absolute;
    display: block;
    content: '';
    right: 0;
    bottom: 0;
    width: 0.1875rem;
    height: 0.1875rem;
    border-radius: 50%;
    box-shadow: 0px 1.58333px 3.16667px rgba(0, 0, 0, 0.46);
    background: var(--color-background);
  }

  body.is-safari & {
    filter: none;
    box-shadow: 0 0.25rem 0.125rem var(--color-default-shadow);
  }

  body.is-safari &__bubble-small,
  body.is-safari &__bubble-big {
    box-shadow: 0 0.25rem 0.125rem var(--color-default-shadow);
  }

  &__items-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 3rem;
  }

  &__items {
    padding: 0 1rem;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow: overlay;
    overflow-y: hidden;
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 3rem;
  }

  &--compact {
    background: var(--color-background-compact-menu-reactions);
    height: 2.25rem;
    top: -2.75rem;
  }

  &--compact &__items {
    padding: 0 0.5rem;
  }

  &--compact &__bubble-big {
    border-color: var(--color-background-compact-menu-reactions);
  }

  &--compact &__bubble-small {
    background: var(--color-background-compact-menu-reactions);
  }

  &__blocked-button {
    width: 2rem !important;
    height: 2rem !important;
    margin-left: 0.5rem !important;
  }

  &--compact &__blocked-button {
    width: 1.5rem !important;
    height: 1.5rem !important;

    i {
      font-size: 1.25rem;
    }
  }
}
