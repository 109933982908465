// @optimization
@mixin while-transition() {
  .Transition > div:not(.Transition__slide--active) & {
    @content;
  }
}

@mixin active-styles() {
  &:not(.disabled):not(:disabled) {
    &:active,
    &.active {
      @content;
    }
    @media (hover: hover) {
      &:hover {
        @content;
      }
    }
  }
}

@mixin overflow-y-overlay() {
  @supports (overflow-y: overlay) {
    overflow-y: overlay;

    //Workaround for Android <= 9
    overflow-x: hidden;
  }
}

@mixin overflow-y-scroll() {
  @supports (overflow-y: scroll) {
    overflow-y: scroll;

    //Workaround for Android <= 9
    overflow-x: hidden;
  }
}

// @optimization
@mixin while-transition() {
  .Transition_slide:not(.Transition_slide-active) & {
    @content;
  }
}

@mixin adapt-padding-to-scrollbar($padding) {
  padding-inline-end: calc($padding - var(--scrollbar-width));
}

@mixin gradient-border-top($width, $cutout: 0px) {
  mask-image: linear-gradient(transparent $cutout, black $width);
}

@mixin gradient-border-bottom($height, $cutout: 0px) {
  mask-image: linear-gradient(to top, transparent $cutout, black $height);
}

@mixin gradient-border-horizontal($borderStart, $borderEnd) {
  mask-image: linear-gradient(
    to right,
    transparent,
    black $borderStart,
    black calc(100% - $borderEnd),
    transparent
  );
}

@mixin gradient-border-left($indent, $cutout: 0px) {
  mask-image: linear-gradient(to right, transparent $cutout, black $indent);
}

@mixin gradient-border-right($indent, $cutout: 0px) {
  mask-image: linear-gradient(to left, transparent $cutout, black $indent);
}

@mixin gradient-border-top-bottom($top, $bottom) {
  mask-image: linear-gradient(
    transparent 0%,
    black $top,
    black calc(100% - $bottom),
    transparent 100%
  );
}

@mixin reset-range() {
  input[type='range'] {
    display: block;
    width: 100%;
    height: 0.75rem;
    margin-bottom: 0.5rem;
    background: transparent;

    &:focus {
      outline: none;
    }

    &::-ms-track {
      width: 100%;
      cursor: pointer;

      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    &::-moz-slider-thumb {
      -moz-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      cursor: pointer;
    }

    &::-moz-range-track,
    &::-moz-range-progress {
      cursor: pointer;
    }
  }
}

@mixin header-mobile {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 2.875rem;
  box-shadow: 0 0.125rem 0.125rem var(--color-light-shadow);

  display: flex;
  flex-direction: row-reverse;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: max(0.75rem, env(safe-area-inset-left));
  padding-right: max(0.5rem, env(safe-area-inset-right));
  background: var(--color-background);

  // Target: Old Firefox (Waterfox Classic)
  @supports not (padding-left: max(0.75rem, env(safe-area-inset-left))) {
    padding-left: 0.75rem;
    padding-right: 0.5rem;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -0.1875rem;
    left: 0;
    right: 0;
    height: 0.125rem;
    box-shadow: 0 0.125rem 0.125rem var(--color-light-shadow);
  }
}
