.DateSuggest {
  display: flex;
  height: 2rem;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;

  .date-item {
    display: flex;
    flex: 1 1 auto;
    min-width: 8rem;
    margin-top: 0.375rem;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-text-secondary);

    .icon-calendar {
      font-size: 1.25rem;
      margin-right: 0.25rem;
    }
  }
}
