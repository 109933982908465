.root {
  :global {
    .modal-header {
      border-bottom: 1px solid var(--color-dividers);
      padding: 0.5rem;

      transition: 0.25s ease-in-out background-color;
    }

    .modal-dialog {
      height: 75%;
      justify-content: center;
      border: none;
      box-shadow: none;
      margin: 0;
      overflow: hidden;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 0;
      border-bottom-right-radius: var(--border-radius-default);
      border-bottom-left-radius: var(--border-radius-default);

      transition: 0.25s ease-in-out background-color;
    }

    @media (max-width: 600px) {
      .modal-dialog {
        background-color: var(--color-background);
        border-radius: 0;
        height: 100%;
        max-width: 100% !important;
      }

      .modal-content {
        max-height: none;
        border-radius: 0;
      }
    }
  }
}

.close-icon {
  position: absolute;
  transform: rotate(-45deg);

  &,
  &::before,
  &::after {
    width: 1.125rem;
    height: 0.125rem;
    border-radius: 0.125rem;
    background-color: var(--color-text-secondary);
    transition: transform var(--slide-transition);
  }

  &::before,
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
  }

  &::before {
    transform: rotate(90deg);
  }

  &.state-back {
    transform: rotate(180deg);

    &::before {
      transform: rotate(45deg) scaleX(0.75) translate(0, -0.375rem);
    }

    &::after {
      transform: rotate(-45deg) scaleX(0.75) translate(0, 0.375rem);
    }
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  transition: opacity 0.25s ease-in-out;
}

.hide {
  opacity: 0;
}

.frame {
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 1;

  &.with-button {
    height: calc(100% - 3.5rem);
  }
}

.main-button {
  position: absolute;
  bottom: 0;
  border-radius: 0;

  z-index: 1;
  transform: translateY(100%);
  transition-property: background-color, color, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;

  &.visible {
    transform: translateY(0);
  }

  &.hidden {
    visibility: hidden;
  }
}

.main-button-spinner {
  position: absolute;
  right: 1rem;
}

.web-app-popup {
  :global(.modal-dialog) {
    max-width: min(30rem, 100%);
  }

  &.without-title :global(.modal-content) {
    padding-top: 0;
  }

  :global(.modal-content) {
    padding-left: 2rem;
  }
}
