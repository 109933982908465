#AppInactive {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    max-width: 23rem;
    margin: auto;
    padding: 2rem;
    line-height: 1.28;
    text-align: center;
    background: var(--color-background);
    border-radius: var(--border-radius-default);
  }

  .title {
    margin-top: 1rem;
  }

  .description {
    color: #4d4d50;
    font-size: 0.875rem;
    margin: 1rem 0;
  }

  img {
    width: 100%;
    max-width: 20rem;
  }
}
