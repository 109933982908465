.select-dropdown {
  .input-group,
  .input {
    cursor: pointer;
    z-index: var(--z-country-code-input-group);
    label {
      color: var(--color-text);
    }
    .Spinner {
      position: absolute;
      top: 1rem;
      right: 1.25rem;
      opacity: 0.5;
      --spinner-size: 1.5rem;
    }

    // Prevent loading additional 10 kB of icomoon font on initial load.
    .css-icon-down {
      position: absolute;
      top: 1.125rem;
      right: 1.5rem;
      width: 0.875rem;
      height: 0.875rem;
      border: 2px solid var(--color-text);
      border-radius: 3px;
      border-top: 0;
      border-left: 0;
      transform: scaleY(1) rotate(45deg);
      /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
      transition: color 0.2s ease, transform 0.2s ease, top 0.2s ease;

      &.open {
        border-color: var(--color-primary);
        transform: scaleY(-1) rotate(45deg);
        top: 1.5rem;
      }
    }
  }

  .bubble {
    width: 100%;
    max-height: 23.5rem;
    overflow-y: auto;
    transform-origin: top center !important;
    box-shadow: 0px 1.58333px 3.16667px rgba(0, 0, 0, 0.14),
      0px 0.316667px 5.7px rgba(0, 0, 0, 0.12),
      0px 0.95px 1.58333px rgba(0, 0, 0, 0.2);
  }

  .MenuItem {
    button {
      display: flex;
      align-items: center;
    }

    &.no-results button {
      justify-content: center;
      padding: 0.5rem 1rem;

      span {
        font-size: 0.875rem;
      }
    }
  }
}

.select-text {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
