.settings-invitation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  .qr-inner {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
  }
  .qr-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 auto 0.875rem;
    padding: 3.75rem 2rem 1rem;
    border-radius: 20px;
    background-color: #6ca5f9;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
    h3 {
      color: white;
    }
    .Avatar {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .qr-container {
    margin-bottom: 0.75rem;
    text-align: center;
  }

  .qr-description {
    font-size: 0.75rem;
    color: #74747b;
    font-weight: 500;
    text-align: center;
  }
  .title {
    color: var(--color-primary);
    font-size: 0.9375rem;
    padding: 1rem 0.5rem 0.375rem 1.375rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  .Button {
    &.link {
      font-size: 0.9375rem;
      font-weight: normal;
      justify-content: flex-start;
      i {
        font-size: 1.35rem;
      }
    }
  }
  .links-list {
    position: relative;
    margin-bottom: 2rem;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      box-shadow: 0 -0.01rem 0 0.01rem var(--color-borders);
    }
  }
  .invite-link {
    .ListItem-button {
      gap: 0.75rem;
      > svg {
        path {
          fill: var(--color-text-secondary);
        }
      }
      > .icon-svg {
        width: 2rem;
        height: 2rem;
        background-color: #3a85f3;
        border-radius: 50%;
        :first-child {
          width: 18px;
        }
        path {
          stroke: white;
        }
        &.error {
          background-color: var(--color-error);
        }
        &.expire {
          background-color: var(--color-success);
        }
        .wick {
          position: absolute;
          transform: rotate(-90deg);
        }
      }
    }
    .info {
      line-height: 1.2;
    }
    .link-title {
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .subtitle {
      font-size: 0.875rem;
      color: var(--color-text-secondary);
    }
    &.ListItem {
      &.chat-item-clickable {
        .ListItem-button {
          padding: 0.5rem 0.875rem;
        }
      }
    }

    &-inactive {
      .ListItem-button {
        > .icon-svg {
          background-color: var(--color-black-opacity-35);
        }
      }
    }
  }
}

.settings-create-link {
  width: 100%;
  .input-group {
    display: flex;
    gap: 2rem;
  }
  .link-value {
    display: flex;
    justify-content: space-between;
  }
  .form-submit button {
    max-width: 360px;
  }
}

.invite-wrap {
  .button-group {
    display: flex;
    gap: 0.75rem;
    .Button {
      flex-grow: 1;
      &.smaller {
        height: 2.4375rem;
        border-radius: var(--border-radius-default-small);
      }
    }
  }
}
