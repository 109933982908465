.MessageSelectToolbar {
  display: flex;
  position: absolute;
  bottom: calc(0.5rem + env(safe-area-inset-bottom));
  left: 0.5rem;
  right: 0.5rem;
  width: auto;
  z-index: 20;
  justify-content: center;
  margin: 0;

  // Target: Old Firefox (Waterfox Classic)
  @supports not (bottom: calc(0.5rem + env(safe-area-inset-bottom))) {
    bottom: 0.5rem;
  }

  .mask-image-disabled &::before {
    left: auto !important;
    right: auto !important;
  }

  .no-composer & {
    width: 100%;
    @media (max-width: 600px) {
      width: calc(100% - 1rem);
    }
  }

  @media (min-width: 601px) {
    left: 1rem;
    right: 1rem;
    bottom: 1rem;

    .no-composer & {
      top: -4.75rem;
    }
  }

  opacity: 1;

  & .MessageSelectToolbar-inner {
    transform: scaleX(1) translateX(0);
  }

  .no-composer .middle-column-footer-button-container + &,
  .no-composer .messaging-disabled + & {
    top: auto;
  }

  body.animation-level-0 & {
    transition: none !important;
  }

  &:not(.shown) {
    opacity: 0;
    pointer-events: none;

    .shown-inner {
      transform: scaleX(var(--toolbar-hidden-scale, 1));
    }

    &.with-composer .MessageSelectToolbar-inner {
      transform: scaleX(var(--toolbar-hidden-scale, 1))
        translateX(var(--toolbar-translate-x, 0));
    }

    &:not(.with-composer) .MessageSelectToolbar-inner {
      transform: scaleX(var(--toolbar-unpin-hidden-scale)) translateX(0);
    }
  }

  &::before {
    z-index: -1;
    max-width: calc(100% * var(--composer-hidden-scale));
    width: 100%;
    left: auto;
    right: auto;
  }

  &-inner {
    width: 100%;
    max-width: calc(100% * var(--composer-hidden-scale));
    display: flex;
    align-items: center;
    padding: 0.063rem 0.1rem;
    background: var(--color-background);
    border-radius: var(--border-radius-messages);
    font-weight: 500;

    transform: scaleX(1);
    transition: transform var(--select-transition),
      opacity var(--select-transition);

    @media (max-width: 600px) {
      padding: 0;
      max-width: none;
    }

    > .Button {
      width: 3rem;
      height: 3rem;
      svg path {
        stroke: var(--color-text-secondary);
      }
      @media (max-width: 600px) {
        width: 2.875rem;
        height: 2.875rem;
      }
    }
  }

  &-count {
    margin-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;

    @media (max-width: 600px) {
      margin-right: 0.5rem;
    }
  }

  &-actions {
    margin-left: auto;
    display: flex;

    .item {
      width: 100%;
      background: none;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      display: flex;
      position: relative;
      overflow: hidden;
      line-height: 1.5rem;
      white-space: nowrap;
      color: var(--color-text);
      --ripple-color: rgba(0, 0, 0, 0.08);
      cursor: pointer;
      unicode-bidi: plaintext;

      padding: 0.6875rem;
      border-radius: 50%;

      &.destructive {
        color: var(--color-error);
        i {
          color: inherit;
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
      }

      &:not(.disabled):active {
        background-color: var(--color-item-active);
        transition: none !important;
      }

      @media (hover: hover) {
        &:hover,
        &:focus {
          background-color: var(--color-chat-hover);
          text-decoration: none;
        }
      }
    }
  }
}
