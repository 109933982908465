.root {
  --border-radius-default: 0.75rem;

  :global {
    .modal-dialog {
      max-width: 25rem;

      @media (max-width: 600px) {
        max-height: 100%;
      }
    }

    .modal-header {
      padding: 0.825rem 0.5rem 0rem 3.125rem;
    }

    .modal-header-condensed {
      padding: 0 !important;
      border-bottom: 1px solid transparent;
      transition: border-color 250ms ease-in-out;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      padding: 0;
      // Full height - header - margins
      max-height: calc(100vh - 3.25rem - 5rem);
      overflow-x: auto;
      padding-bottom: env(safe-area-inset-bottom);
      @supports not (padding-bottom: env(safe-area-inset-bottom)) {
        padding-bottom: 0;
      }
    }

    .symbol-menu-button {
      flex-shrink: 0;
      background: none !important;
      width: 3.5rem !important;
      height: 3.5rem !important;
      padding: 0 !important;
      align-self: flex-end;
    }

    .symbol-menu-button,
    .mobile-symbol-menu-button {
      margin-right: -1.75rem;
      margin-left: -0.5rem !important;
      color: var(--color-composer-button);
    }

    .mobile-symbol-menu-button {
      margin-left: 0 !important;
      margin-right: -1.25rem !important;
      width: 2.875rem;
      height: 2.875rem;
    }
    .attachment-modal-symbol-menu {
      bottom: 0;
      left: 0;
    }
  }

  :global(body.keyboard-visible) & :global(.modal-content) {
    padding-bottom: 0;
  }

  &.mobile :global {
    .modal-dialog {
      margin: 0;
      max-width: 100% !important;
      align-self: end;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.mobile:global(:not(.open)) :global(.modal-dialog) {
    transform: translate3d(0, 8rem, 0);
  }

  &.mobile.symbolMenuOpen :global(.modal-dialog) {
    transition: var(--layer-transition);

    transform: translate3d(
      0,
      calc(
        (
            var(--symbol-menu-footer-height) + var(--symbol-menu-height) -
              env(safe-area-inset-bottom)
          ) * -1
      ),
      0
    );

    @supports not (bottom: env(safe-area-inset-bottom)) {
      transform: translate3d(
        0,
        calc(
          (var(--symbol-menu-footer-height) + var(--symbol-menu-height)) * -1
        ),
        0
      );
    }
  }

  &.header-border :global(.modal-header-condensed) {
    border-bottom-color: var(--color-borders);
  }
}

.attachments {
  max-height: 26rem;
  min-height: 5rem;
  overflow: auto;
  flex-shrink: 1;

  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0 0.25rem;
  padding: 0.5rem 0.5625rem 0;

  @media (max-width: 600px) {
    max-height: 80vh;
  }
}

.attachments-bottom-padding {
  padding-bottom: 0.5rem;
}

.caption-wrapper {
  position: relative;
  padding: 0 0.8125rem;

  border-top: 1px solid transparent;
  transition: border-color 250ms ease-in-out;

  :global {
    .form-control {
      background: var(--color-background);
    }

    .MentionTooltip {
      right: 0 !important;
      z-index: 0;
    }
  }
}

.caption-top-border {
  border-top-color: var(--color-borders);
}

.caption {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.drop-target {
  display: flex;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--border-radius-default);
    pointer-events: none;

    opacity: 0;
    transition: 250ms opacity;
    z-index: 1;
  }

  &::before {
    background-image: var(--drag-target-border-hovered);
    background-color: var(--color-background);
  }

  &::after {
    content: attr(data-attach-description);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary);
  }
}

.hovered {
  .drop-target::before {
    opacity: 0.95;
  }

  .drop-target::after {
    opacity: 1;
  }

  .caption-wrapper,
  .attachments,
  :global(.input-scroller) {
    pointer-events: none;
  }
}

.send-wrapper {
  align-self: flex-end;
  position: relative;
  padding-bottom: 0.5rem;
}

.send {
  height: 2.5rem;
  padding: 0 1rem;
}

:global {
  .CustomSendMenu {
    bottom: 2.25rem;

    .is-pointer-env & > .backdrop {
      width: 100%;
      top: -2rem;
      bottom: auto;
      height: 3.5rem;
    }
  }
}
