.RightSearch {
  height: 100%;
  padding: 0 0.5rem;
  overflow-y: auto;

  .helper-text {
    padding: 1rem;
    margin-bottom: 0.125rem;
    font-weight: 500;
    color: var(--color-text-secondary);
    unicode-bidi: plaintext;
    text-align: initial;
  }
}
