.captcha-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  box-shadow: 0 1px 2px 0 rgba(#000000, 0.1);
  padding: 0.5rem 0.625rem 0.5rem 0.75rem;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
}
.captcha-content {
  display: flex;
  align-items: center;
  gap: 0.875rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.captcha-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.captcha-privacy {
  a {
    color: #a6a6a6;
  }
  margin: 0;
  font-size: 0.5rem;
  color: #a6a6a6;
  font-weight: 500;
}

.captcha-checkbox-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.checked {
    .captcha-checkbox {
      transition: all 0.25s;
      opacity: 0;
    }
    .captcha-checkbox-checkmark {
      transition: all 0.5s;
      width: 1.75rem;
    }
  }
  &.error {
    .captcha-spiner {
      opacity: 1;
      visibility: visible;
    }
    .captcha-checkbox {
      transition: all 0.25s;
      opacity: 0;
    }
  }
}

.captcha-checkbox {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border: 2px solid #c1c1c1;
  border-radius: 2px;
  background: white;
  transition: none;
  opacity: 1;
  &:hover {
    border-color: #b2b2b2;
  }
}

.captcha-checkbox-checkmark {
  position: absolute;
  left: 0;
  width: 0;
  height: 1.75rem;
  background-image: var(--captcha-checkmark-data);
  background-repeat: no-repeat;
  transition: none;
}

.captcha-spiner {
  position: absolute;
  left: -4px;
  top: -4px;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #f9f9f9;
  border: 6px solid #468ee5;
  border-radius: 1.5rem;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-sizing: content-box;
  outline: 0;
  opacity: 0;
  visibility: hidden;
  animation: spinner-spin linear 2.5s infinite;
  transition-duration: 1s;
}

@keyframes spinner-spin {
  0% {
    transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(135deg);
  }
  25% {
    transform: rotateZ(245deg);
  }
  60% {
    transform: rotateZ(700deg);
  }
  75% {
    transform: rotateZ(810deg);
  }
  100% {
    transform: rotateZ(1080deg);
  }
}
