@import '../../../styles/mixins';

.SymbolMenu {
  &.attachment-modal-symbol-menu {
    position: absolute;
    z-index: 10000;
  }

  &:not(.mobile-menu) {
    @media (max-height: 800px) {
      --symbol-menu-height: 40vh;
    }
  }

  &.mobile-menu {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-background);
    z-index: var(--z-symbol-menu-mobile);

    transition: transform var(--layer-transition);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    transform: translate3d(
      0,
      calc(
        var(--symbol-menu-height) + var(--symbol-menu-footer-height) +
          env(safe-area-inset-bottom)
      ),
      0
    );

    &.open:not(.in-attachment-modal) {
      transform: translate3d(0, 0, 0);

      body.is-media-viewer-open & {
        transform: translate3d(
          0,
          calc(var(--symbol-menu-height) + var(--symbol-menu-footer-height)),
          0
        );
      }
    }

    &.open.in-attachment-modal {
      z-index: calc(var(--z-modal) + 1);
      transform: translate3d(
        0,
        calc(var(--symbol-menu-height) + var(--symbol-menu-footer-height)),
        0
      );
    }

    // Target: Old Firefox (Waterfox Classic)
    @supports not (padding-right: env(safe-area-inset-right)) {
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
      transform: translate3d(
        0,
        calc(var(--symbol-menu-height) + var(--symbol-menu-footer-height)),
        0
      );
    }

    body.animation-level-0 & {
      transition: none;
    }

    &.left-column-open {
      transform: translate3d(100vw, 0, 0) !important;
    }
  }

  &-main {
    height: var(--symbol-menu-height);
    max-height: calc(
      100vh - var(--symbol-menu-footer-height) - env(safe-area-inset-bottom)
    );

    // Target: Old Firefox (Waterfox Classic)
    @supports not (
      max-height:
        calc(
          100vh - var(--symbol-menu-footer-height) - env(safe-area-inset-bottom)
        )
    ) {
      max-height: calc(100vh - var(--symbol-menu-footer-height));
    }
  }

  &-footer {
    border-top: 1px solid var(--color-borders);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 2px var(--color-default-shadow);
    position: relative;

    .Button {
      flex-shrink: 0;
      width: 2.125rem !important;
      height: 2.125rem;
      margin: 0 0.05rem;
      padding: 0;
      &.round {
        i {
          font-size: 1.0625rem;
        }
        &.activated {
          i {
            font-size: 1.25rem;
          }
        }
      }

      &.translucent:not(.activated) {
        color: var(--color-text-secondary);
      }
      &.activated {
        pointer-events: none;
      }

      &.symbol-tab-button {
        margin: 0 0.05rem;
      }

      &.symbol-search-button {
        position: absolute;
        left: 0.25rem;
      }

      &.symbol-delete-button {
        position: absolute;
        right: 0.25rem;
      }
    }
  }

  .Transition.slide {
    height: 100%;

    .Loading {
      height: var(--symbol-menu-height);
    }
  }

  .is-pointer-env & {
    > .backdrop {
      position: absolute;
      top: -1rem;
      left: 0;
      right: auto;
      width: 3.5rem;
      height: 4.5rem;
    }

    &.attachment-modal-symbol-menu > .backdrop {
      bottom: 0;
      top: auto;
    }
  }

  // TODO Remove this monster with context menu refactor
  .Button.bot-menu:not(.open) ~ &,
  .Button.bot-commands ~ &,
  .Button.send-as-button ~ & {
    .is-pointer-env & > .backdrop {
      left: 3rem;
      width: 3.25rem;
    }
  }

  .Button.bot-menu.open ~ & {
    .is-pointer-env & > .backdrop {
      left: calc(var(--bot-menu-text-width, 0) + 3rem);
    }
  }

  .bubble {
    --offset-y: 4rem;
    border-radius: var(--border-radius-default);
    box-shadow: none;
    filter: drop-shadow(0px 1.58333px 3.16667px rgba(0, 0, 0, 0.14))
      drop-shadow(0px 0.316667px 5.7px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 0.95px 1.58333px rgba(0, 0, 0, 0.2));
    width: calc(
      var(--symbol-menu-width) + 0.25rem
    ); // Reserve width for scrollbar
    padding: 0;
    overflow: hidden;

    @supports (overflow: overlay) {
      width: var(--symbol-menu-width);
    }
  }

  .picker-disabled {
    height: var(--symbol-menu-height);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-secondary);
  }

  .symbol-close-button {
    display: none;
    position: absolute;
    right: max(0.25rem, env(safe-area-inset-right));
    top: max(0.25rem, env(safe-area-inset-top));

    // Target: Old Firefox (Waterfox Classic)
    @supports not (right: max(0.25rem, env(safe-area-inset-right))) {
      right: 0.25rem;
      top: 0.25rem;
    }
  }

  @media (orientation: landscape) {
    .symbol-close-button {
      display: block;
    }
  }
}

.symbol-set {
  margin-bottom: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;

  &.symbol-set-locked::before {
    content: '';
    display: block;
    position: absolute;
    inset: -0.25rem;
    top: 0.75rem;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><rect width='100%' height='100%' style='stroke: rgba(112, 117, 121, 0.7); width: calc(100% - 4px); height: calc(100% - 4px);' fill='none' stroke-dashoffset='5' stroke-width='2' stroke-dasharray='8' stroke-linecap='round' rx='8' ry='8' x='2' y='2' /></svg>");
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(var(--color-text-secondary-rgb), 0.75);
  }

  &-name {
    font-size: 0.75rem;
    line-height: 1.6875rem;
    font-weight: 500;
    margin: 0;
    padding: 0 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    unicode-bidi: plaintext;
    z-index: 1;
    background-color: var(--color-background);
  }

  &-locked-icon {
    margin-right: 0.25rem;
  }

  &-remove {
    right: 0;
    position: absolute;
    font-size: 1rem;
    cursor: pointer;
  }

  &-container {
    text-align: initial;
  }

  &-button {
    flex-shrink: 0;
    width: 2.125rem !important;
    height: 2.125rem;
    margin: 0 0.25rem;
    padding: 0;
  }

  @include while-transition() {
    overflow: hidden;
  }

  .symbol-set-container {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, var(--emoji-size, 4rem));
    grid-gap: 0.5rem;
    padding: 0.3125rem;

    @media (max-width: 600px) {
      grid-gap: 0.5rem;
    }

    &:not(.shown) {
      display: block;
    }

    &.closing {
      transition: none;
    }

    > .EmojiButton,
    > .StickerButton {
      margin: 0;
    }
  }
}
