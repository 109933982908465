@import '../../../styles/mixins';

#settings_content {
  > .Transition {
    height: 100%;
    overflow: hidden;
  }
}

.settings-wrap {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.settings-main-header {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}
.setting-info {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.settings-more-menu {
  .bubble {
    width: auto;

    .MenuItem button {
      padding-left: 1rem !important;
      padding-right: 2rem !important;
    }
  }
}

.settings-container {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 1rem;
  @media (min-width: 1276px) {
    width: calc(100% - var(--right-column-width));
  }

  .infinite-scroll {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .Menu {
    .bubble {
      border-radius: var(--border-radius-default);
    }
  }
  &.ai-space {
    padding-top: 1.5rem;
  }
}

.settings-content {
  //height: calc(100% - var(--header-height));
  height: 100%;
  width: 100%;
  @include overflow-y-overlay();
  background-color: var(--color-background);

  &.no-border,
  &.two-fa,
  &.local-passcode,
  &.password-form {
    border-top: none;
  }

  &.password-form .input-group.error label::first-letter {
    text-transform: uppercase;
  }

  &.infinite-scroll {
    display: flex;
    flex-direction: column;

    & > .custom-scroll {
      flex: 1 1 auto;
      height: auto;
    }
  }

  .chat-list {
    padding: 0.5rem 0;
    .ListItem-button {
      padding-left: 1rem;
      padding-right: 1rem;
      > .icon-svg {
        svg path {
          fill: var(--color-text-secondary);
        }
      }
    }
  }

  .subscriptions-list {
    .DropdownMenu {
      .icon-svg {
        svg path {
          fill: var(--color-text-secondary);
        }
      }
    }
  }

  .settings-icon-locked {
    align-self: center;
    margin-right: 0.25rem !important;
    font-size: 1rem !important;
  }

  #monkey {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .AnimatedEmoji {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
}

.settings-layout {
  .settings-content {
    transform: translate3d(0, 0, 0);
    transition: transform var(--layer-transition);
    #Main.right-column-open & {
      width: calc(100% - var(--right-column-width));
      transform: translate3d(calc(var(--right-column-width) / -2), 0, 0);
      .settings-container {
        width: 100%;
      }
    }
  }
}

.settings-subscriptions {
  .type-select {
    margin-bottom: 1rem;
    .Button {
      padding: 0.5rem 1.5rem 0.5rem 1rem;
      .css-icon-down {
        top: 0.5rem;
        width: 0.6rem;
        height: 0.6rem;
        border-width: 2px;
      }
    }
  }
}

.settings-content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem 1rem;
  text-align: center;
  background-color: var(--color-background);
  box-shadow: inset 0 -0.0625rem 0 0 var(--color-background-secondary-accent);
  margin-bottom: 0.625rem;

  &.no-border {
    margin-bottom: 0;
    box-shadow: none;
  }

  .input-group {
    width: 100%;
  }
}

.settings-content-icon {
  margin-bottom: 2.5rem;

  &.opacity-transition:not(.shown) {
    display: block;
    visibility: hidden;
  }
}

.settings-main-menu-premium .PremiumIcon {
  margin-right: 2rem;
}

.settings-main-menu {
  background-color: var(--color-background-secondary);
  padding-bottom: 0.75rem;
  .Button {
    &.full-width {
      margin: 0 auto;
      max-width: calc(100% - 2rem);
    }
  }
}

.ChatExtraActions {
  display: flex;
}

.settings-edit-profile {
  padding-bottom: 1rem;
  .input-group {
    display: flex;
    column-gap: 1rem;
  }
  // :not(textarea).form-control {
  //   height: 3rem;
  // }
  .form-submit {
    button {
      max-width: 360px;
    }
  }
}

.settings-item {
  padding: 0.5rem 0.25rem 0.5rem 0.5rem;
  margin-bottom: 0.75rem;
  min-height: 3.75rem;
  background-color: var(--color-background);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.05),
    0px 2px 10px rgba(0, 0, 0, 0.04);
  .ListItem-button {
    padding: 0.5rem 0.75rem;
    gap: 1rem;
    .Button.smaller.round {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      margin-right: 0;
    }
  }
  .switcher-wrap {
    padding-right: 0.5rem;
  }
  &.ListItem {
    min-height: 3.125rem;
    padding: 0 0 0 0.5rem;
  }
  &-header {
    display: flex;
    text-align: left;
    width: 100%;
    align-items: center;
    font-weight: normal;
    margin-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 0.5rem;
    > :first-child {
      flex-grow: 1;
    }
    .invite-link {
      overflow: hidden;
    }
  }

  &-slider {
    margin-top: 2rem;
  }

  &-description {
    font-size: 0.875rem;
    color: var(--color-text-secondary);
    margin-top: -0.5rem;
    margin-bottom: 0;
    line-height: 1.125rem;

    .settings-content.two-fa &,
    .settings-content.password-form &,
    .settings-content.local-passcode & {
      font-size: 1rem;
    }

    .settings-edit-profile & {
      margin-bottom: 0;
      padding-bottom: 1.5rem;
    }

    &[dir='rtl'] {
      text-align: right;
      unicode-bidi: plaintext;
    }
  }

  &-description-larger {
    font-size: 1rem;
    line-height: 1.3125rem;
    color: var(--color-text-secondary);
    margin-top: 2rem;
    margin-bottom: 0.75rem;

    &[dir='rtl'] {
      text-align: right;
    }
  }

  &-middle {
    border-bottom: 1px solid var(--color-placeholders);
    padding-bottom: 2rem;
    margin-bottom: 1.5rem;
    .ListItem,
    .Radio {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.02rem;
        background-color: var(--color-underline);
      }
    }
    .ListItem {
      padding: 0.125rem 0;
      &::after {
        bottom: 0;
      }
      .ListItem-button {
        padding: 0.782rem 1.25rem;
      }
    }
    .Radio {
      margin: 0;
      padding-top: 0.8125rem;
      padding-bottom: 0.8125rem;
    }
  }

  p {
    margin: 0;
    font-size: 0.875rem;
  }

  .title {
    font-size: 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .icon-svg {
      padding: 0.25rem;
    }
    // path {
    //   stroke: var(--color-text-secondary);
    // }
  }

  h5 {
    margin-top: 0.875rem;
  }

  .subtitle {
    font-size: 0.75rem;
    color: var(--color-text-secondary);
  }

  .ListItem {
    padding: 0.125rem 0;
    .ListItem-button {
      padding: 0.75rem;
      gap: 1.25rem;
      border-radius: 0.75rem;
    }
    & + .ListItem {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0.75rem;
        width: 100%;
        height: 0.02rem;
        background-color: var(--color-underline);
      }
    }
    &:last-child {
      margin-bottom: 0;
    }

    .multiline-menu-item {
      white-space: initial;
      flex-grow: 1;

      &.full-size {
        width: 100%;
        overflow: hidden;
      }

      .date {
        float: right;
        font-size: 0.75rem;
        margin-left: 1rem;
        color: var(--color-text-secondary);
      }

      .title,
      .subtitle {
        display: block;
        text-align: left;
      }

      .title {
        line-height: 1.25rem;
      }

      .subtitle {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: var(--color-text-secondary);

        & + .subtitle {
          margin-top: -0.25rem;
        }

        &.black {
          color: var(--color-text);
        }

        &.tight {
          line-height: 1.3125rem;
          margin-bottom: 0.1875rem;
        }
      }
    }

    &.destructive {
      .ListItem-button {
        color: var(--color-error);

        i {
          color: inherit;
        }
      }
    }

    &.blocked-list-item {
      margin-bottom: 0.5rem;

      .ListItem-button {
        align-items: center;
        text-align: left;
        padding: 0.5rem;
      }

      .Avatar {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
      }

      .contact-name {
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: 0.25rem;
        font-weight: 500;
      }

      .contact-phone {
        font-size: 0.875rem;
        line-height: 1rem;
        color: var(--color-text-secondary);
      }
    }

    &[dir='rtl'] {
      .multiline-menu-item {
        .title,
        .subtitle {
          text-align: right;
        }

        .date {
          float: left;
          margin-left: 0;
          margin-right: 1rem;
        }
      }
    }
  }

  .RangeSlider {
    margin-bottom: 1.0625rem;
  }

  .Checkbox,
  .radio-group {
    margin: 0 -1rem 0.5rem;
  }

  .radio-group {
    .Radio:last-child {
      margin-bottom: 0;
    }

    .Checkbox {
      margin-left: 0;
    }
  }

  .Radio + .Radio,
  .Checkbox + .Checkbox {
    margin-top: 2rem;
  }

  &__current-value {
    display: flex;
    align-items: center;
    margin-inline-start: auto;
    padding-inline-start: 0.5rem;
    font-size: 0.9375rem;
    font-weight: 500;
    .css-icon-right {
      margin-left: 1rem;
      transform: scale(0.9) rotate(-45deg);
      border-color: var(--color-text-secondary);
    }
  }
}

.ChatExtra {
  .settings-item-header:not(:last-child) {
    margin-bottom: 0;
  }
  .settings-item:not(:last-child) {
    box-shadow: none;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 1.25rem;
      width: 100%;
      height: 0;
      box-shadow: 0 -0.015rem 0 0.015rem var(--color-borders);
    }
  }
}

.settings-scroll-wrapper {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.settings-fab-wrapper {
  height: calc(100% - var(--header-height));
  position: relative;
  overflow: hidden;

  .settings-content {
    height: 100%;
  }

  .FloatingActionButton {
    right: 1.5rem;

    &[dir='rtl'] {
      right: auto;
      left: 1.5rem;
    }
  }
}

.settings-quick-reaction {
  .Radio-main .label {
    display: flex;
    align-items: center;
  }

  .ReactionStaticEmoji {
    margin-inline-end: 1rem;
    width: 1.5rem;
  }
}

.SettingsDefaultReaction {
  .current-default-reaction {
    margin-inline-end: 2rem;
  }
}

.settings-privacy {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  dl {
    & > .settings-item-description {
      margin-top: 1rem;
      margin-bottom: 0;
      padding-left: 1.375rem;
    }
  }
  dd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0.75rem 1rem 0.75rem 1.25rem;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 2.375rem;
      width: 100%;
      height: 0.02rem;
      background-color: var(--color-underline);
    }
    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    svg {
      vertical-align: middle;
    }
    .Avatar {
      display: inline-flex;
      background-size: cover;
    }
  }
  .settings-item-description {
    margin-top: 0.75rem;
    margin-bottom: 0;
  }

  .input-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h5 {
    color: var(--color-primary);
  }

  .form-submit button {
    max-width: 360px;
  }

  .items-wrapper {
    padding-bottom: 1rem;
  }
}
.button-to-go-wrapper {
  display: flex;
  justify-content: center;
  .button-to-go {
    font-size: 0.8125rem;
    height: auto;
    padding: 0.4375rem 1.0625rem;
    border-radius: var(--border-radius-default-smaller);
  }
}

.before-delete {
  h5 {
    padding-left: 1.25rem;
  }
}

.Loyalty-page {
  height: 100%;
  @include overflow-y-scroll();
  .settings-container {
    min-height: auto;
  }
  > .btn-group {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--color-background);
  }
  .Button {
    &.text {
      border-radius: 0;
      height: 2.875rem;
      color: var(--color-text-secondary);
      &.active {
        color: var(--color-text);
        border-bottom: 1px solid var(--color-text);
      }
    }
    &[aria-label='Copy'] {
      [stroke] {
        stroke: #3a85f3;
      }
    }
  }
  .heading-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 1.5rem 0.5rem 2.25rem;
    background-image: url('/src/assets/images/bg-progress.svg');
    background-size: cover;
    &.referrals {
      background-image: url('/src/assets/images/bg-referrals.svg');
    }
    h4 {
      margin-bottom: 0;
    }
    .amount {
      [stroke] {
        stroke: white;
      }
    }
  }
}

.referral-description {
  text-align: center;
  padding: 2.5rem 0;
  line-height: 1;
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;

    svg {
      margin-left: 0.5rem;
      [fill] {
        fill: var(--color-text);
      }
    }
  }
  p {
    color: var(--color-text-secondary);
  }
}

@media (max-width: 600px) {
  .settings-edit-profile {
    .input-group {
      flex-direction: column;
      margin-bottom: 0rem !important;
    }
  }
  .settings-item {
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .settings-privacy {
    .input-group {
      flex-wrap: wrap;
    }
  }
}
