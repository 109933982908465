.info-channel {
  max-width: 720px;
  margin: 0 auto;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    background: #F7F7F7;
    border-radius: 12px;
    padding: 30px 20px;
    margin-bottom: -15px;
  }

  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #929298;
    margin-bottom: 6px;
  }

  &__subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #070708;
  }
}