.PremiumIcon {
  flex-shrink: 0;
  display: flex;
  width: 1rem;
  height: 1rem;

  &.big {
    width: 1.5rem;
    height: 1.5rem;
  }

  --color-fill: var(--color-primary);

  & > svg {
    width: 100%;
    height: 100%;
  }

  &.clickable {
    cursor: pointer;
    pointer-events: auto;
  }
}
