.root {
  :global(.modal-dialog) {
    max-width: 28rem;
  }
}

.avatar {
  width: 5rem;
  height: 5rem;
  font-size: 3.5rem;
  margin: 0 auto 1rem;
  border-radius: 1rem;

  :global(.Avatar__media) {
    border-radius: 1rem;
  }
}

.title {
  justify-content: center;
}

.note {
  color: var(--color-text-secondary);
  font-size: 0.875rem;
  text-align: center;
}

.box {
  background: var(--color-background-secondary);
  padding: 1rem 1rem 0.5rem;
  border-radius: var(--border-radius-default);
  margin: 1rem 0;
}

.action-header {
  margin-top: 1px;
}

.action-name {
  margin-right: auto;
}

.header-button {
  margin-right: -0.5rem;
}
