@import "../../../styles/mixins";

.root {
  --color-primary: var(--color-text);

  height: 100%;
}

.main {
  --symbol-set-gap-size: 0.25rem;

  position: relative;
  height: calc(100% - 3rem);

  overflow-y: auto;
  overflow-x: hidden;

  padding: 0.5rem 0.25rem;
  @include adapt-padding-to-scrollbar(0.25rem);

  &_customEmoji {
    padding: 0.5rem 0.75rem;
    @include adapt-padding-to-scrollbar(0.75rem);
  }

  :global(.bubble) {
    border-radius: var(--border-radius-default) !important;
  }
}

.header {
  height: 3rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0 0.375rem;

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0);

  &::-webkit-scrollbar {
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
  }

  // Spacer to counter last button margin not being accounted in scroll width
  &::after {
    content: "";
    display: block;
    flex-shrink: 0;
    width: 1px;
    height: 1rem;
  }

  &.headerWithBorder {
    /* stylelint-disable-next-line plugin/whole-pixel */
    box-shadow: inset 0 -0.5px 0 0 var(--color-borders-alternate);
  }

  // Allows `shared-canvas` to fill the whole available width
  & > :global(.shared-canvas-container) {
    display: inline-block;
  }

  :global(.shared-canvas) {
    max-width: 1280px; // STICKER_PICKER_MAX_SHARED_COVERS * (STICKER_SIZE_PICKER_HEADER + 10 * 2)
    z-index: 1;
  }
}

.stickerCover {
  display: inline-grid;
  vertical-align: middle;
  grid-template-areas: "cover";
  justify-content: center;
  align-items: center;

  width: 2.25rem !important;
  height: 2.25rem;
  margin: 0.375rem 0.125rem !important;
  padding: 0;
  border-radius: var(--border-radius-messages-small) !important;

  &:global(.StickerButton) {
    background-size: 1.875rem;

    :global(.sticker-media),
    :global(.AnimatedSticker) {
      position: static;
      grid-area: cover;
      width: 1.875rem;
      height: 1.875rem;
    }
  }

  &.activated {
    color: var(--color-text-lighter);
    background-color: var(--color-interactive-element-hover);

    :global(.theme-dark) & {
      --color-text-lighter: var(--color-text);
    }
  }
}

.pickerDisabled {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
