.root {
  position: relative;

  display: block !important;
}

.preview {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
