.form-wrapper {
  display: flex;
  gap: 16px;
}

.form-inner {
  width: 100%;
  max-width: 360px;
  .amount {
    margin-bottom: 1rem;
    justify-content: center;
    .input {
      width: auto;
      margin-bottom: 0;
    }
    > svg {
      flex: 0 0 auto;
    }
  }
}

.form-height {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  position: relative;
  .Loading .Spinner {
    --spinner-size: 8.25rem;
  }
}

.transfer-form {
  .description {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-placeholders);
    font-size: 0.9375rem;
    gap: 0.125rem;
    margin-bottom: 0.5rem;
    svg {
      margin-left: 0.25rem;
      //margin-top: -2px;
    }
    [stroke] {
      stroke: var(--color-placeholders);
    }
  }
  .form-submit {
    margin-top: 40px;
  }
}

.form-card-title {
  margin-bottom: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #070708;
}

.form-sum-number {
  font-size: 48px;
  line-height: 56px;
  color: #070708;
  text-align: center;
}

.form-sum-balance {
  margin: 24px 0;
  font-size: 0.8125rem;
  line-height: 1.15;
  text-align: center;
  color: var(--color-text-secondary);
  [stroke] {
    stroke: var(--color-text-secondary);
  }
}

.form-ello-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25rem;
  height: 3.875rem;
  padding: 0.25rem 0.375rem;
  border-radius: 0.5rem;
  background-color: #eeeeef;
  margin: 0 auto;
}

.center-error .input .input-notification--error {
  text-align: center;
}
