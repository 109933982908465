.MessageContextMenu {
  position: absolute;
  font-size: 1rem;

  .scrollable-content {
    overflow-x: hidden;
    overflow-y: overlay;
    padding: 0.5rem 0;
    overscroll-behavior: contain;
  }

  &.compact {
    .scrollable-content {
      padding: 0.25rem 0;
    }
    .bubble {
      transition: opacity 0.15s cubic-bezier(0.2, 0, 0.2, 1),
        transform 0.15s cubic-bezier(0.2, 0, 0.2, 1) !important;
      overflow: initial;
      background: rgba(255, 255, 255, 0.96);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &.with-reactions .bubble {
    margin-top: 3.5rem;
  }

  .backdrop {
    touch-action: none;
  }

  &--seen-by {
    max-width: 14rem;
  }

  &--seen-by-label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .avatars {
    display: flex;
    align-self: center;
    margin-inline-start: auto;
    padding-inline-start: 1rem;

    .Avatar {
      border: 0.0625rem solid var(--color-background);
      margin-right: 0;
      box-sizing: content-box;

      &:not(:first-child) {
        margin-left: -0.1875rem;
      }
    }
  }
}
