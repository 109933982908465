.PickerSelectedItem {
  display: flex;
  align-items: center;
  background: var(--color-chat-hover);
  height: 1.875rem;
  min-width: 2rem;
  font-size: 0.8125rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  padding-right: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  flex-shrink: 1;
  transition: background-color 0.15s ease;

  max-width: calc(50% - 0.5rem);

  &.minimized {
    padding-right: 0;
  }

  &:hover {
    background-color: var(--color-item-active);
  }

  &.closeable:hover {
    background-color: rgba(var(--color-error-rgb), 0.16);

    .item-remove {
      opacity: 1;
    }

    .Avatar,
    .item-icon {
      opacity: 0;
    }
  }

  &.search-date {
    .item-remove {
      background: var(--color-primary);
    }

    &:hover {
      background: var(--color-chat-hover);
    }

    .item-name {
      font-size: 0.875rem;
    }
  }

  .SearchInput & {
    flex: 1 0 auto;
    position: relative;
    top: 0.25rem;
    left: -0.125rem;

    color: var(--color-text-secondary);
  }

  .Avatar,
  .item-icon {
    width: 1.875rem;
    height: 1.875rem;
    opacity: 1;
    flex-shrink: 0;
    transition: opacity 0.15s ease;

    .Avatar__icon,
    i {
      font-size: 1rem;
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem;
    border-radius: 50%;
    background-color: var(--color-primary);
    color: white;

    i {
      font-size: 1.25rem;
      position: relative;
      top: -1px;
    }
  }

  .item-name {
    margin-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .emoji.emoji-small {
      width: 1.125rem;
      height: 1.125rem;
      background-size: 1.125rem;
      vertical-align: -2px;
    }
  }

  .item-remove {
    position: absolute;
    left: 0;
    top: 0;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-error);
    font-size: 1.5rem;
    color: white;
    opacity: 0;
    transition: opacity 0.15s ease;
  }

  &.forum-avatar {
    border-start-start-radius: 0.625rem;
    border-end-start-radius: 0.625rem;

    .item-remove {
      border-radius: 0.625rem;
    }
  }

  &[dir='rtl'] {
    padding-left: 1rem;
    padding-right: 0;

    &.minimized {
      padding-right: 0;
      padding-left: 0;
    }

    .SearchInput & {
      left: auto;
      right: -0.125rem;
    }

    .item-name {
      margin-left: 0;
      margin-right: 0.5rem;
    }

    .item-remove {
      left: auto;
      right: 0;
    }
  }
}
