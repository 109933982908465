.WallpaperTile {
  height: 0;
  padding-bottom: 100%;
  cursor: pointer;
  position: relative;

  .media-inner,
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .media-inner {
    overflow: hidden;
    transform: scale(1);
    transition: transform 0.15s ease;

    img,
    canvas {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &::after {
    content: "";
    display: block;
    border: 2px solid var(--color-primary);
    opacity: 0;
    transition: opacity 0.15s ease;
  }

  // &.selected {
  //   &::after {
  //     opacity: 1;
  //   }

  //   .media-inner {
  //     transform: scale(0.9);
  //   }
  // }

  .spinner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.check-green-wrapper {
  position: absolute;
  z-index: 50;
  left: calc(50% - 14px);
  bottom: 10px;
}
