.Badge-transition {
  transform: scale(0);
}

.Badge-wrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  svg {
    path {
      stroke: var(--color-placeholders);
    }
  }
  .Badge {
    svg path {
      stroke: var(--color-white);
    }
  }
}

.Badge {
  min-width: 1.5rem;
  height: 1.4375rem;
  background: var(--color-placeholders);
  border-radius: 0.625rem;
  padding: 0 0.47168rem;
  color: white;
  font-size: 0.8125rem;
  line-height: 1.4375rem;
  font-weight: 500;
  text-align: center;
  flex-shrink: 0;

  body.is-macos & {
    line-height: 1.5rem;
  }

  body.is-ios &:not(.unopened) {
    line-height: 1.375rem;
    min-width: 1.375rem;
    height: 1.375rem;
    padding: 0 0.375rem;
  }

  &.mention {
    background: var(--color-text-green);
  }
  &.unread:not(.muted),
  &.unopened:not(.muted) {
    background: var(--color-primary);
    color: var(--color-white);
  }

  &.unopened {
    width: 0.5rem;
    height: 0.5rem;
    min-width: auto;
    min-height: auto;
    padding: 0;
    align-self: center;
  }

  &.pinned {
    color: var(--color-pinned);
    background: transparent;
    width: 1.5rem;
    padding: 0;

    i {
      font-size: 1.5rem;
    }
  }

  &.reaction:not(.muted) {
    background: #ed504f;
  }

  &.mention,
  &.reaction {
    min-width: 1.375rem;
    width: 1.375rem;
    height: 1.375rem;
    padding: 0.2rem 0;

    i {
      //font-size: 1rem;
      vertical-align: super;
    }

    body.is-ios & {
      width: 1.375rem;
      padding: 0.25rem;

      i {
        font-size: 0.875rem;
      }
    }
  }
}
