.WebLink {
  min-height: 3rem;
  padding: 0.25rem 0 0 3.75rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  position: relative;

  & + & {
    margin-top: 1.5rem;
  }

  &.without-media {
    padding-top: 0;
    &::before {
      content: attr(data-initial);
      width: 3rem;
      height: 3rem;
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--color-webpage-initial-background);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--border-radius-messages-small);
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.5rem;
    }
  }

  .Media {
    position: absolute;
    left: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
    cursor: default !important;
    padding-bottom: unset !important;
    border-radius: var(--border-radius-messages-small) !important;
  }

  .site-name,
  .site-description,
  .site-title {
    word-break: break-word;
    text-align: initial;
  }

  .site-name {
    display: block;
    color: var(--color-links);
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .site-title {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.0625rem;
    line-height: 1.3125rem;
  }

  .sender-name,
  .site-description {
    margin-bottom: 0;
    color: var(--color-text-secondary);
    line-height: 1.125rem;
    max-height: 2.25rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .sender-name {
    margin-top: 0.25rem;
  }

  .site-description .emoji {
    width: 1rem !important;
    height: 1rem !important;
  }

  &[dir='rtl'] {
    padding: 0.25rem 3.75rem 0 0;

    .Media,
    &.without-media::before {
      left: auto;
      right: 0;
    }

    .content {
      text-align: right;
    }
  }
}
