.DropTarget {
  border-radius: var(--border-radius-default);
  background: var(--color-background);
  padding: 1.5rem;
  flex: 1 1 auto;
  width: 100%;
  max-width: 43.75rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.3125rem;
  display: flex;
  color: var(--color-text-secondary);
  //box-shadow: 0 1px 2px var(--color-default-shadow);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04),
    0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.2);

  @media (max-height: 350px) {
    padding: 0.75rem;
  }

  &.hovered {
    .target-content {
      color: var(--color-primary);
      background-image: var(--drag-target-border-hovered);
    }
    svg path {
      stroke: var(--color-primary);
    }
  }

  & + & {
    margin-top: 0.3125rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .target-content {
    pointer-events: none;
    background-image: var(--drag-target-border);
    border-radius: 0.5rem;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s color, 0.2s background-image;
  }

  svg {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1.25rem;
    path {
      stroke: var(--color-text-secondary);
      transition: 0.2s stroke;
    }
    @media (max-height: 520px) {
      width: 2rem;
      height: 2rem;
      margin-bottom: 1rem;
    }

    @media (max-height: 450px) {
      width: 1.5rem;
      height: 1.5rem;
      margin-bottom: 0.5rem;
      margin-top: 0.25rem;
    }
  }

  .title {
    font-size: 1.125rem;
    font-weight: 500;
    //letter-spacing: -0.18px;

    @media (max-height: 450px) {
      font-size: 1rem;
    }
  }

  .description {
    font-size: 1rem;
    letter-spacing: 0.3px;

    @media (max-height: 450px) {
      font-size: 0.875rem;
    }
  }
}
