.ProgressSpinner {
  position: relative;
  z-index: 1;
  width: 3.375rem;
  height: 3.375rem;
  background: rgba(0, 0, 0, 0.25)
    url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwLjcxNyA5Ljc1TDE4LjMgMi4xNjdhLjY4NC42ODQgMCAxMC0uOTY3LS45NjdMOS43NSA4Ljc4MyAyLjE2NyAxLjJhLjY4NC42ODQgMCAxMC0uOTY3Ljk2N0w4Ljc4MyA5Ljc1IDEuMiAxNy4zMzNhLjY4NC42ODQgMCAxMC45NjcuOTY3bDcuNTgzLTcuNTgzIDcuNTgzIDcuNTgzYS42ODEuNjgxIDAgMDAuOTY3IDAgLjY4NC42ODQgMCAwMDAtLjk2N0wxMC43MTcgOS43NXoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9Ii43NSIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==)
    no-repeat 49% 49%;
  border-radius: 50%;
  cursor: pointer;

  &.no-cross {
    background: transparent !important;
    pointer-events: none;
  }

  &.square {
    background-image: none;

    &::after {
      content: '';
      position: absolute;
      width: 0.8125rem;
      height: 0.8125rem;
      border-radius: 0.125rem;
      background-color: #fff;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &.size-s,
  &.size-m {
    width: auto;
    height: auto;
    background: rgba(0, 0, 0, 0.25)
      url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguMjE4IDcuNWw1LjYzMy01LjYzM2EuNTA4LjUwOCAwIDEwLS43MTgtLjcxOEw3LjUgNi43ODIgMS44NjcgMS4xNDlhLjUwOC41MDggMCAxMC0uNzE4LjcxOEw2Ljc4MiA3LjVsLTUuNjMzIDUuNjMzYS41MDguNTA4IDAgMTAuNzE4LjcxOEw3LjUgOC4yMThsNS42MzMgNS42MzNhLjUwNi41MDYgMCAwMC43MTggMCAuNTA4LjUwOCAwIDAwMC0uNzE4TDguMjE4IDcuNXoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSIjRkZGIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+)
      no-repeat 50% 50%;

    &.square {
      background-image: none;
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &.size-xl {
    width: 3.25rem;
    height: 3.25rem;

    svg {
      width: 3rem;
      height: 3rem;
      margin: 0.125rem;
    }

    circle {
      stroke-width: 3px;
    }
  }

  &.transparent {
    background-color: transparent !important;
  }

  svg {
    display: block;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    animation: 4s linear 0s infinite ProgressSpinnerAnimation;
  }

  circle {
    transition: stroke-dashoffset 0.5s;
  }
}

@keyframes ProgressSpinnerAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
