.StickerSetCard {
  .settings-item &.ListItem {
    margin-bottom: 0.5rem;
  }

  .StickerButton,
  .Button {
    width: 3rem;
    height: 3rem;
    margin: 0 0.5rem 0 0;
    padding: 0;
    flex: 0 0 3rem;
  }

  .install-button {
    box-sizing: content-box;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .multiline-menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &[dir="rtl"] {
    .StickerButton,
    .Button {
      margin: 0 0 0 0.5rem;
    }
  }
}
