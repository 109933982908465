.Avatar {
  --color-user: var(--color-primary);
  --radius: 50%;
  flex: none;
  align-items: center;
  justify-content: center;
  width: 3.375rem;
  height: 3.375rem;
  border-radius: var(--radius);
  color: white;
  font-weight: 500;
  display: flex;
  white-space: nowrap;
  user-select: none;
  position: relative;
  &:not(.no-bg) {
    background: linear-gradient(var(--color-white) -125%, var(--color-user));
    //background: var(--color-user);
  }

  &__media {
    border-radius: var(--radius);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .emoji {
    width: 1rem;
    height: 1rem;
  }

  &__icon {
    font-size: 1.25rem;
  }

  &.size-micro {
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;

    .emoji {
      width: 0.5625rem;
      height: 0.5625rem;
    }
  }

  &.size-tiny {
    width: 1.875rem;
    height: 1.875rem;
    font-size: 0.875rem;
    line-height: 1.9375rem;
    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-mini {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.75rem;

    .emoji {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &.size-small {
    width: 2.125rem;
    height: 2.125rem;
    font-size: 0.875rem;
    line-height: 2.0625rem;
    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-small-mobile {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.875rem;

    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-medium {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.1875rem;
    line-height: 1;
    .emoji {
      width: 1rem;
      height: 1rem;
    }
  }

  &.size-large {
    font-size: 1.375rem;
    i {
      font-size: 1.625rem;
    }

    .emoji {
      width: 1.3125rem;
      height: 1.3125rem;
    }
  }

  &.size-jumbo {
    width: 5rem;
    height: 5rem;
    font-size: 2.25rem;

    &__i {
      font-size: 6rem;
    }

    .emoji {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  &.online {
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 2px;
      right: 0;
      width: 0.625rem;
      height: 0.6255rem;
      border-radius: 42%;
      // border: 2px solid var(--color-background);
      box-shadow: 0 0 0 1.5px var(--color-background);
      background-color: #44be2e;
      flex-shrink: 0;
    }
  }

  &.interactive {
    cursor: pointer;
  }

  .poster {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &.forum {
    --radius: var(--border-radius-forum-avatar);
  }
}
