.NewContactModal {
  .modal-dialog {
    max-width: 28rem;
  }

  &__new-contact {
    display: flex;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    &-fieldset {
      flex: 1;
      margin-inline-start: 1rem;
      @media (max-width: 500px) {
        margin-inline-start: 0;
        margin-top: 1rem;
      }
    }
  }

  &__profile {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    &-info {
      margin-inline-start: 1rem;
    }
  }

  &__user-status {
    color: var(--color-text-secondary);
  }

  &__phone-number {
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  &__help-text {
    font-size: 0.9375rem;
    color: var(--color-text-secondary);
  }
}
