.EmojiPicker {
  --emoji-size: 2rem;

  height: 100%;

  &-main {
    height: calc(100% - 3rem);
    overflow-y: auto;
    padding: 0.4375rem;

    @media (max-width: 600px) {
      padding: 0.5rem 0.25rem;
    }
  }

  &-header {
    height: 2.125rem;
    border-bottom: 1px solid var(--color-borders);
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 0 2px var(--color-default-shadow);
    .Button {
      &.round i {
        font-size: 1.25rem;
      }
      &.translucent {
        color: var(--color-text-secondary);
        &.activated {
          color: var(--color-primary);
        }
      }
    }

    @media (max-width: 600px) {
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none;
      scrollbar-color: rgba(0, 0, 0, 0);

      &::-webkit-scrollbar {
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0);
      }

      .symbol-set-button {
        display: inline-flex;
      }

      // Spacer to counter last button margin not being accounted in scroll width
      &::after {
        content: '';
        display: block;
        flex-shrink: 0;
        width: 1px;
        height: 1rem;
      }
    }
  }
}
