.MediaViewerFooter {
  position: absolute;
  bottom: 0;
  padding: 1rem 0;
  width: 100%;
  transition: opacity 0.15s;

  #MediaViewer.zoomed & {
    display: none;
  }

  @media (max-height: 640px) {
    padding: 0.5rem 0 0;
  }

  &.mobile {
    background: linear-gradient(to top, #000 0%, rgba(0, 0, 0, 0) 100%);

    &.is-for-video {
      opacity: 0;
      pointer-events: none;
      padding-bottom: 5rem;

      .video-controls-visible &:not(.is-hidden) {
        opacity: 1;
        pointer-events: auto;
      }
    }
    .media-viewer-footer-content {
      opacity: 1;
      z-index: 1;
    }
    .media-text.multiline::before {
      display: none;
      background: none;
    }
  }

  body.ghost-animating & {
    opacity: 0;
  }

  .media-viewer-footer-content {
    position: relative;
    max-width: var(--messages-container-width);
    margin: auto;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.15s;

    &:hover {
      opacity: 1;
    }
  }

  &.is-hidden {
    opacity: 0;
  }

  .media-text {
    margin-bottom: 0;
    overflow: auto;
    padding: 0 0.5rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.9375rem;
    letter-spacing: 0.025rem;
    max-height: 4.25rem;

    @media (max-height: 640px) {
      max-height: 2.75rem;
    }

    .emoji:not(.custom-emoji) {
      width: 0.9375rem;
      height: 0.9375rem;
      vertical-align: -2px;
    }

    &.multiline {
      &::before {
        content: "";
        position: absolute;
        left: -0.75rem;
        right: -0.75rem;
        top: -0.25rem;
        bottom: -0.25rem;
        background: rgba(0, 0, 0, 0.75);
        border-radius: var(--border-radius-default);
        z-index: var(--z-below);
      }
    }
  }

  a {
    color: var(--color-links);

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
