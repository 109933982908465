.TextFormatter {
  transform: translate(-50%, -3.25rem);
  z-index: 1;

  &,
  &-link-control {
    position: absolute;
    background: var(--color-background);
    border-radius: var(--border-radius-messages);
    padding: 0.5rem 0.375rem;
    box-shadow: 0 1px 2px var(--color-default-shadow);
  }

  &-link-control {
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    .Modal & {
      max-width: 100%;
    }

    @media (max-width: 600px) {
      max-width: calc(100vw - var(--text-formatter-left) + 3rem);
    }

    input {
      border: none !important;
      outline: none !important;
      width: 100%;
      color: var(--color-text);
      background-color: var(--color-background);
    }
  }

  &-buttons,
  &-link-url-confirm {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &-divider {
    width: 1px;
    height: 1.75rem;
    margin: 0 0.25rem;
    background-color: var(--color-borders);
  }

  &-link-url-input-wrapper {
    width: 20rem;
    position: relative;
    max-width: 100%;

    @media (max-width: 600px) {
      max-width: 90vw;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1rem;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    &::before {
      left: 0;
      background: linear-gradient(to right, var(--color-background) 0.25rem, transparent 1rem);
    }

    &::after {
      right: 0;
      background: linear-gradient(to left, var(--color-background) 0.25rem, transparent 1rem);
    }

    &.mask-left {
      &::before {
        opacity: 1;
      }
    }

    &.mask-right {
      &::after {
        opacity: 1;
      }
    }
  }

  &-link-url-confirm {
    flex-shrink: 0;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.shown {
      opacity: 1;
    }
  }

  &.link-control-shown {
    .TextFormatter-link-control {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .Button {
    width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    font-size: 1.5rem;
    margin: 0 0.125rem;
    border-radius: var(--border-radius-messages-small);
    flex-shrink: 0;
    cursor: pointer;

    &.active {
      background-color: var(--color-primary) !important;
      color: white;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
