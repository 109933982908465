.GifPicker {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 6.25rem;
  grid-gap: 0.25rem;
  grid-auto-flow: dense;
  height: 100%;
  overflow-y: auto;
  padding: 0.25rem;

  @supports (overflow: overlay) {
    overflow-y: overlay;
  }

  .Loading, .picker-disabled {
    grid-column: 1 / -1;
    height: var(--menu-height);
  }
}
