.InlineBotTooltip {
  .switch-pm .title {
    margin: 0 auto;
    font-weight: 500;
  }

  &.gallery {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-flow: dense;
    grid-gap: 1px;
    padding: 0;

    @media (max-width: 600px) {
      grid-template-columns: repeat(4, 1fr);
    }

    .switch-pm {
      grid-column: 1 / -1;
      .ListItem-button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .StickerButton {
      width: initial;
      height: 0;
      margin: 0;
      padding-bottom: 100%;
      border-radius: var(--border-radius-default);

      .AnimatedSticker,
      img,
      canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;

        @media (min-width: 600px) {
          top: 0.25rem;
          left: 0.25rem;
          width: calc(100% - 0.5rem) !important;
          height: calc(100% - 0.5rem) !important;
        }
      }
    }
  }

  &:not(.gallery) .GifButton {
    width: 4rem;
    height: 4rem;
    margin: 0.5rem;
  }
}
