.container {
  background-color: var(--color-background);
  padding: 1.5rem 1.5rem 0;
  box-shadow: inset 0 -0.0625rem 0 0 var(--color-background-secondary-accent);
  margin-bottom: 0.625rem;
}

.header {
  font-size: 1rem;
  color: var(--color-text-secondary);
  margin-bottom: 2rem;
  position: relative;

  &[dir="rtl"] {
    text-align: right;
  }
}

.description {
  font-size: 0.875rem;
  color: var(--color-text-secondary);

  margin-top: 1rem;
  margin-bottom: 0;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
}

.sortableContainer {
  position: relative;

  :global(.draggable-knob) {
    margin-top: -0.25rem;
  }
}

.item {
  margin-bottom: 0;
  margin-left: -1rem;
  margin-right: -1rem;
}
