.Picker {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  .picker-header {
    padding: 0.5rem 0 0.25rem 0.5rem;
    border-bottom: 1px solid var(--color-borders);
    display: flex;
    flex-flow: row wrap;
    flex-shrink: 0;

    overflow-y: auto;
    max-height: 28rem;

    .input {
      margin-bottom: 0.25rem;
      margin-left: 0.5rem;
      flex-grow: 1;
    }

    .form-control {
      height: 2rem;
      border: none;
      border-radius: 0;
      padding: 0;
      box-shadow: none;
      font-size: 0.9375rem;
    }
  }

  .picker-list {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0.5rem;

    @media (max-width: 600px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .no-results {
    height: 100%;
    margin: 0;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-secondary);
  }
  .section-help {
    white-space: pre-line;
  }
}
