.information-content {
  padding-top: 2rem;
  dl {
    margin: 1.75rem 0;
  }
  dd {
    font-size: 0.9375rem;
    position: relative;
    margin: 0;
    padding: 0.625rem 1.25rem;
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.02rem;
        background-color: var(--color-underline);
      }
    }
  }
}

.information-item-middle {
  [stroke] {
    stroke: var(--color-primary);
  }
  .ListItem {
    &:not(:last-child) {
      border-bottom: 1px solid #f2f2f2;
    }
    &-button {
      padding: 0.5625rem 1rem;
    }
  }
}
