.GroupCallTopPane {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 2.875rem;
  overflow: hidden;
  box-shadow: 0 2px 2px var(--color-light-shadow);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.375rem 0.5rem 0.375rem 0.75rem;
  background: var(--color-background);
  z-index: -1;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -0.1875rem;
    left: 0;
    right: 0;
    height: 0.125rem;
    box-shadow: 0 0.125rem 0.125rem var(--color-light-shadow);
  }

  &.is-hidden {
    display: none;
  }

  @media (max-width: 600px) {
    &.has-pinned-offset {
      top: calc(100% + 2.875rem);
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 0.875rem;
      color: var(--color-text);
    }

    .participants {
      font-size: 0.75rem;
      color: var(--color-text-secondary);
    }
  }

  .avatars {
    display: flex;
    flex-direction: row;
    align-items: center;

    .Avatar {
      margin: 0 0 0 -0.75rem;

      &:first-child {
        width: 2rem;
        height: 2rem;
      }

      &:not(:first-child) {
        width: 2.25rem;
        height: 2.25rem;
        border: 0.125rem solid var(--color-background);
      }
    }
  }

  .join {
    height: 1.5rem;
    border-radius: 1.5rem;
    font-weight: 500;
    padding: 1rem 1rem;
    width: auto;
  }
}

@media (min-width: 1440px) {
  #Main.right-column-open .MiddleHeader .GroupCallTopPane {
    width: calc(100% - var(--right-column-width));
  }
}
