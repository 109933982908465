.GifButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.25rem;
  background-color: transparent;
  position: relative;

  &:hover {
    .gif-unsave-button {
      opacity: 0.8;
    }
  }

  &:last-child {
    margin-bottom: 1rem;
  }

  &.vertical {
    grid-column-end: span 1;
  }

  &.horizontal {
    grid-column-end: span 2;
  }

  &.interactive {
    cursor: pointer;
  }

  .thumbnail {
    width: 100%;
    height: 100%;
    background: transparent no-repeat center;
    background-size: cover !important;
  }

  .preview, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    -webkit-touch-callout: none;
    user-select: none;
  }

  .Spinner {
    position: absolute;
    pointer-events: none;
  }

  .gif-unsave-button {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    width: 1rem;
    height: 1rem;
    padding: 0.125rem;
    border-radius: 0.25rem;
    transition: 0.15s opacity ease-in-out;

    &-icon {
      font-size: 0.75rem;
    }

    opacity: 0;
    z-index: 1;
  }

  .gif-context-menu {
    position: absolute;

    .bubble {
      width: auto;
    }
  }
}
