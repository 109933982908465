.CalendarModal {
  .modal-dialog {
    max-width: 20.5rem;
    overflow-y: auto;
    .modal-content {
      padding: 1.25rem;
      overflow-y: auto;
      @media (max-width: 600px) {
        padding: 1rem;
      }
      @media (max-height: 500px) {
        padding-bottom: 2.5rem;
      }
    }
  }

  .timepicker {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;

    .form-control {
      max-width: 5rem;
      text-align: center;

      &:first-child {
        margin-right: 1rem;
      }
      &:last-child {
        margin-left: 1rem;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    .Button {
      text-transform: none;
    }
  }
  h3 {
    font-size: 2.125rem;
    color: var(--color-primary);
    font-weight: normal;
    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
  }
  .month-selector {
    display: flex;
    align-items: center;
    Button.round {
      i {
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--color-text);
      }
    }
    h4 {
      text-transform: capitalize;
      flex: 1;
      margin: 0;
      font-size: 0.875rem;
      text-align: center;
      @media (max-width: 600px) {
        margin-left: 0.75rem;
      }
    }

    .Button.hidden {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .day-button {
    position: relative;
    border-radius: 4rem;
    outline: none !important;
    font-weight: 500;
    @media (max-width: 600px) {
      margin: 0.25rem 0.375rem;
    }

    &.weekday {
      margin-bottom: 0;
      color: #74747b;
      span {
        font-size: 0.8125rem;
        font-weight: 300;
      }
    }

    &.clickable {
      cursor: pointer;
      &:hover {
        background-color: var(--color-interactive-element-hover);
      }

      &.selected,
      &.range {
        background-color: var(--color-primary);
        color: white;
      }
    }

    &.disabled {
      opacity: 0.25;
      pointer-events: none;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
    }
  }

  .calendar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 17rem;
    padding: 0.7rem;
    // margin: 1.5rem -0.5rem 0.5rem;
    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .year-selection {
    .Menu {
      .bubble {
        min-width: auto;
        max-height: 14rem;
      }
    }
    .MenuItem.compact {
      padding: 0 0.25rem;
    }
  }

  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
  }

  .Button {
    &.text {
      padding: 0.25rem 1rem 0.25rem 0;
    }
  }
}
