.Skeleton {
  position: relative;
  background-color: var(--color-skeleton-background);
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.inline {
    display: inline-block;
    height: 1rem;
    border-radius: 0.5rem;
  }

  &.round {
    border-radius: 50%;
  }

  &.rounded-rect {
    border-radius: 1rem;
  }

  &.pulse::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-skeleton-foreground);
    animation: skeleton-pulse 2s ease-in-out 0.5s infinite;

    @keyframes skeleton-pulse {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &.wave::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, var(--color-skeleton-foreground) 50%, transparent 100%);
    animation: skeleton-wave 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes skeleton-wave {
      0% {
        transform: translateX(-100%);
      }
      50% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
  }
}
