$icons: 'android', 'apple', 'brave', 'chrome', 'edge', 'firefox', 'linux',
  'opera', 'safari', 'samsung', 'ubuntu', 'unknown', 'vivaldi', 'windows',
  'xbox';

@mixin device-icon($icon-name) {
  .iconDevice__#{$icon-name} {
    background-image: url('../../../assets/devices/#{$icon-name}.svg');
  }
}

.SettingsActiveSession {
  :global(.modal-dialog) {
    max-width: 25rem;
  }
  :global(.modal-content) {
    padding: 0.5rem 1rem 0;
  }
}

.iconDevice {
  width: 4.5rem;
  height: 4.5rem;
  background-repeat: no-repeat;
  background-size: 4.5rem;
  margin: 0 auto 1rem;
}

@each $icon in $icons {
  @include device-icon($icon);
}

.title {
  text-align: center;
  margin-bottom: 0.25rem;
  line-height: 1.2;
}

.note,
.date {
  color: var(--color-text-secondary);
  font-size: 0.875rem;
  text-align: center;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 1.625rem;
  background: var(--color-background-secondary);
  padding: 1.625rem 0.5rem 1.625rem 1.25rem;
  border-radius: var(--border-radius-default);
  margin: 1rem 0;
}

.item {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  line-height: 1.125;
  svg {
    flex-shrink: 0;
    [fill] {
      fill: var(--color-text-secondary);
    }
    [stroke] {
      stroke: var(--color-text-secondary);
    }
  }
}

.item_title {
  font-weight: 500;
  margin-bottom: 0.375rem;
}
.item_subtitle {
  font-size: 0.875rem;
  color: var(--color-text-secondary);
}

.actionHeader {
  margin-top: 1px;
}

.actionName {
  margin-right: auto;
}

.footerButton {
  margin-bottom: 1rem;
}
