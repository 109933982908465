.FakeIcon {
  border: 1px solid var(--color-error);
  border-radius: 0.375rem;
  color: var(--color-error);
  font-size: 0.625rem;
  padding: 0.125rem 0.25rem;
  line-height: initial;
  margin-inline: 0.25rem;
  font-weight: 500;
  text-transform: capitalize;
}
