.ChatMessage {
  &:first-child {
    margin-top: 0.5rem;
  }
  &:hover,
  &.selected {
    .Avatar.online::after {
      border-color: var(--color-chat-hover);
    }
  }

  .ListItem-button {
    padding: 0.25rem 0.5rem;
  }

  .info {
    .title {
      flex-grow: 1;
    }

    h3 {
      font-size: 1rem;
      width: auto;
    }

    .subtitle {
      color: var(--color-text-secondary);

      .matching-text-highlight {
        color: var(--color-text);
        background: #cae3f7;
        border-radius: 0.25rem;
        padding: 0 0.125rem;
        display: inline-block;

        .theme-dark & {
          --color-text: #000;
        }
      }
    }

    .message {
      flex-grow: 1;
      color: var(--color-text-secondary);
      overflow: hidden;
      text-overflow: ellipsis;

      .sender-name {
        color: var(--color-text);

        &::after {
          content: ": ";
        }
      }

      .media-preview-spoiler {
        filter: blur(1px);
      }

      .media-preview--image {
        width: 1.25rem;
        height: 1.25rem;
        object-fit: cover;
        border-radius: 0.125rem;
        vertical-align: -0.25rem;
        margin-inline-start: 0.125rem;
        margin-inline-end: 0.25rem;
      }

      .icon-play {
        position: relative;
        display: inline-block;
        font-size: 0.75rem;
        color: #fff;
        margin-inline-start: -1.25rem;
        margin-inline-end: 0.5rem;
        bottom: 0.0625rem;
      }
    }
  }

  .info-row {
    display: flex;
    justify-content: space-between;
  }

  &[dir="rtl"] {
    .subtitle {
      text-align: right;
    }
  }
}
