.MediaViewerActions {
  display: flex;
  margin-inline-start: auto;
  margin-inline-end: -0.375rem;

  .Button {
    color: white;
    svg {
      [fill] {
        fill: white;
      }
    }
  }
}

.MediaViewerActions-mobile {
  position: relative;
  color: var(--color-text);

  .ProgressSpinner {
    position: absolute;
    top: 0;
    left: 0;
  }

  .Button {
    color: white;
    svg {
      [fill] {
        fill: white;
      }
    }
  }
}
