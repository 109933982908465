.certificate-info-wrapper {
  background: var(--color-background-secondary);
  .qr-container {
    display: flex;
    justify-content: center;
  }
  .logo {
    position: absolute;
    left: 50%;
    bottom: 100%;
    width: 5rem;
    height: 5rem;
    background: var(--color-background);
    box-shadow: 0px 1.33469px 2.33571px rgba(0, 0, 0, 0.17);
    border-radius: 2.1875rem;
    transform: translate(-50%, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.info-block {
  position: relative;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  background-color: var(--color-background);
  border-radius: var(--border-radius-default-bigger);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
  line-height: 1.2;
  h3 {
    margin-bottom: 0.75rem;
  }
  h4 {
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
  }
  li {
    margin-bottom: 0.75rem;
  }
  &.has-qr {
    padding-top: 3.5rem;
    padding-bottom: 2.5rem;
    margin-top: 2.5rem;
    text-align: center;
  }
  ul {
    padding-left: 1.125rem;
  }
  .description {
    padding: 1rem;
    border-radius: var(--border-radius-messages);
    background: #f7f7f7;
    font-weight: 500;
    .row {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
    }
    .subtitle {
      color: var(--color-text-secondary);
    }
  }
}
