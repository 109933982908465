.bank-transfer {
  padding-top: 2.625rem;
  &__wrap {
    width: 100%;
    max-width: 720px;
    padding: 41px 10px 76px;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 1.5625rem;
  }

  &__label {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    min-height: 1.2em;
  }

  .input-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    > *,
    .select-dropdown {
      margin-bottom: 0;
    }
  }

  .with-description {
    .bank-transfer__gray {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }

  &__gray {
    font-size: 0.875rem;
    line-height: 1.3;
    color: var(--color-text-secondary);
  }

  &__sum {
    font-weight: 600;
    font-size: 30px;
    line-height: 33px;
    color: var(--color-primary);
    [stroke] {
      stroke: var(--color-primary);
    }
  }

  &__max-width {
    margin: 0 auto;
    max-width: 360px;
  }

  &__inner-type {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  &__recipient {
    width: 100%;
  }

  &__currency {
    width: 100%;

    > p {
      margin-top: 8px;
    }
  }

  .request-wrapper {
    max-width: 22.5rem;
    margin: 0 auto;
    .bank-transfer__sum {
      margin-bottom: 2.125rem;
    }
  }
  .request-block {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: var(--color-background-secondary);
    border-radius: var(--border-radius-messages);
    font-size: 0.8175rem;
    line-height: 1.15;
    dd {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      margin-bottom: 1rem;
      span {
        color: var(--color-text-secondary);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    b {
      text-align: right;
    }
  }
  @media (max-width: 600px) {
    .input-group {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.requisits-wrap {
  max-width: 360px;
  margin: 0 auto;
  .Button {
    border-radius: 0.625rem;
    &.link {
      font-size: 0.8125rem;
      text-transform: uppercase;
      color: var(--color-10);
      border: 1px solid var(--color-10);
      margin-bottom: 2.5rem;
    }
  }
}

.requisits-list {
  .loading-wrap {
    height: 7rem;
  }

  .item {
    display: flex;
    padding: 0.5rem;
    gap: 0.25rem;
    border-radius: var(--border-radius-default);
    border: 1px solid var(--color-borders-checkbox);
    margin-bottom: 0.75rem;
    .title {
      font-weight: 500;
      font-size: 0.8125rem;
      padding-left: 2.125rem;
    }
    .description {
      font-size: 0.8125rem;
      padding-left: 2.125rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    [stroke] {
      stroke: var(--color-text);
    }
  }
  .Radio {
    margin-bottom: 0;
    padding-left: 2.125rem;
    .Radio-main {
      &::before {
        left: 2px;
      }
      &::after {
        left: 5px;
      }
    }
    .label {
      font-weight: 500;
    }
  }
  .left-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.25rem;
    flex-grow: 1;
  }
}

.form-submit-bottom {
  padding-bottom: 76px;
}
