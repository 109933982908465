@media (min-width: 451px) {
  .modalDialog :global(.modal-dialog) {
    max-width: 32rem !important;
  }
}

.closeButton {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.avatar {
  margin: 0 auto 1.5rem;
}

.headerText {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.description,
.premiumFeatures {
  text-align: center;
  margin: 0 auto 2rem;
  max-width: 25rem;
}

.premiumFeatures {
  font-size: 0.9375rem;
  color: var(--color-text-secondary);
}

.options {
  margin-bottom: 2.5rem;
}

.button {
  height: 3rem;
  background: linear-gradient(88.39deg, #6C93FF -2.56%, #976FFF 51.27%, #DF69D1 107.39%);
  font-size: 1rem;
  font-weight: 600;
}
