.ai-space {
  --color-chat-hover: var(--color-background-secondary);
  h3 {
    font-size: 1.5rem;
  }
  .ListItem {
    .ListItem-button {
      padding: 0.75rem 0;
      gap: 1rem;
      transition: var(--layer-transition);
      @media (min-width: 768px) {
        &:hover {
          padding: 0.75rem 1rem;
        }
      }
    }
    .fullName {
      font-size: 1.125rem;
    }
    .info {
      flex-grow: 1;
      overflow: hidden;
    }
    .open-chat {
      padding: 0.4375rem 1.125rem;
      border-radius: 0.5rem;
      background-color: var(--color-message-reaction);
      color: var(--color-primary);
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .SearchInput {
    border-radius: 0.875rem;
    input {
      height: 2.9375rem;
    }
    > .icon {
      top: 0.75rem;
    }
  }
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 0.875rem;
      line-height: 1.3;
    }
  }
  .title-icon {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 1.5rem;
    margin-bottom: 0.75rem;
  }
  .item-middle {
    border-radius: 0.625rem;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 2px 10px 0px rgba(0, 0, 0, 0.04);
    background-color: var(--color-background);
    padding: 0.75rem 0 1rem;
    margin-bottom: 0.5rem;
  }
  .filter-group {
    padding: 1.5rem 0;
    gap: 0.75rem;
    transition: var(--layer-transition);
    position: relative;
    flex-wrap: wrap;
    &.show-all {
      flex-wrap: wrap;
    }

    .Button {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 500;
      padding: 0.75rem 1rem;
      gap: 0.75rem;
      &.without-icon {
        padding: 0.75rem 1.5rem;
      }
    }
    .icon {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
    }
  }
}

.ai-banner {
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url('../../../../assets/images/bg-stars.png');
    background-position: center;
  }
  position: relative;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    #1ecbff 0%,
    #004aa9 22.16%,
    #683af3 85.39%,
    #bb3af3 100%
  );
  color: white;
  line-height: 1.1875;
  padding: 1rem;
  margin-bottom: 1.5rem;
  h3 {
    text-indent: 0.5rem;
  }
  .description,
  dd {
    border-radius: 0.75rem;
    padding: 0.75rem 1rem;
    background: rgba(255, 255, 255, 0.12);
  }

  .description {
    margin-bottom: 0.75rem;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
  }

  dd {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 0.5rem;
    margin-bottom: 0;
  }

  .Button {
    &.primary {
      background-color: white;
      color: var(--color-text);
      text-transform: uppercase;
      &:not(.disabled):not(:disabled):hover {
        color: white;
      }
    }
    &.full-width {
      max-width: 100%;
    }
  }
  .Avatar {
    background-size: cover;
    background-image: url('../../../../assets/payment/text-bg-transactions.jpg');
    &.ai-image {
      background-image: url('../../../../assets/payment/image-bg.jpg');
    }
  }
}

.bots-list {
  .ListItem {
    &.chat-item-clickable {
      .ListItem-button {
        padding: 0.5rem;
      }
    }
  }
}
