.MediaResult {
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
