.Media {
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  .video-duration {
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    background: rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 0.75rem;
    padding: 0 0.3125rem;
    border-radius: 0.1875rem;
    line-height: 1.125rem;
  }

  .media-miniature {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
