.root {
  flex: 1 calc(50% - 0.5rem);
  min-width: 0;
  position: relative;

  display: flex;
  align-items: center;

  border-radius: var(--border-radius-default);
  overflow: hidden;
}

.preview {
  width: 100%;
  height: 12rem;
  object-fit: cover;
}

.duration {
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 0.75rem;
  position: absolute;
  left: 0.1875rem;
  top: 0.1875rem;
  z-index: 1;
  padding: 0 0.375rem;
  border-radius: 0.75rem;
  line-height: 1.125rem;
  -webkit-user-select: none;
  user-select: none;
}

.single .preview {
  height: auto;
  max-height: 24rem;
}

.no-grouping {
  flex-basis: 100%;
}

.file {
  margin: 0.5rem;
  flex-grow: 1;
  min-width: 0;
}

.overlay {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: flex;
  overflow: hidden;
}

.action-item {
  display: block;
  padding: 0.3125rem;
  color: white;
  border-radius: var(--border-radius-messages-small);
  transition: 0.2s background-color ease-in-out;
  cursor: pointer;
  background-color: var(--color-black-opacity-60) !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }

  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
}

.delete-file {
  font-size: 1.5rem;
  color: var(--color-text-secondary);
  background-color: transparent !important;
  margin-right: 1rem;
}
