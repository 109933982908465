.chat-theme {
  position: relative;

  &__box {
    width: 101px;
    height: 136px;
    background-image: url('../../../assets/images/theme.svg');
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    border: 2px solid transparent;
    transition: border 0.2s;

    &:hover {
      border: 2px solid #0A49A5;
    }
  }

  &__theme-first {
    position: absolute;
    top: 16px;
    right: 6px;
  }

  &__theme-second {
    position: absolute;
    top: 54px;
    left: 6px;
  }
}