.MessageOutgoingStatus {
  width: 1.1875rem;
  height: 1.3125rem;
  //overflow: hidden;
  //display: inline-block;
  line-height: 1;
  font-size: 1.1875rem;

  i {
    //background: var(--background-color);
    svg {
      path {
        stroke: var(--accent-color);
      }
    }
  }

  .icon-message-succeeded {
    padding-left: 0.125rem;
  }

  .Transition {
    width: 100%;
    height: 100%;
  }

  .MessageOutgoingStatus--failed::before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    border-radius: 50%;
    background: white;
  }

  .icon-message-failed {
    background: none;
    color: var(--color-error);
    z-index: 1;
    position: relative;
  }
}
