.AddChatMembers {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;

  &-inner {
    height: 100%;
    overflow: hidden;
  }
}
