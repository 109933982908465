.menu {
  position: absolute;
  z-index: var(--z-reaction-picker);

  @media (max-width: 600px) {
    max-width: 100%;
    left: 0 !important;
    right: 0 !important;
  }
}

.menuContent {
  --border-radius-default: 1.25rem;

  :global(body:not(.no-menu-blur)) & {
    --color-background: var(--color-background-compact-menu);

    backdrop-filter: blur(10px);
  }

  width: calc(var(--symbol-menu-width) + var(--scrollbar-width));
  height: var(--symbol-menu-height);
  padding: 0 !important;
  transform-origin: 9rem 4.625rem !important;

  &:global(.bubble) {
    transform: scale(0.8) !important;
    transition: opacity 150ms cubic-bezier(0.2, 0, 0.2, 1), transform 150ms cubic-bezier(0.2, 0, 0.2, 1) !important;
    --offset-x: -0.75rem;
    --offset-y: calc(100% + 0.625rem);
  }

  &:global(.bubble.open) {
    transform: scale(1) !important;
  }

  @media (max-width: 440px) {
    max-width: min(calc(100% - 1rem), 26.25rem);
    left: 50% !important;
    right: auto !important;

    &:global(.bubble) {
      transform: scale(0.5) translateX(-50%) !important;
      transform-origin: 0 3.5rem !important;
    }

    &:global(.bubble.open) {
      transform: scale(1) translateX(-50%) !important;
    }
  }
}

.onlyReactions {
  height: auto;
  transform-origin: 9rem 1.75rem !important;

  &:global(.bubble) {
    --offset-y: calc(100% + 0.1875rem);
  }
}

.hidden {
  display: none !important;
}
