.Notification-container {
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  z-index: var(--z-notification);
  @media (min-width: 1276px) {
    transition: transform var(--layer-transition);
  }

  @media (max-width: 600px) {
    top: 0.5rem;
    right: 0.5rem;
  }

  & ~ & {
    margin-top: 0.25rem;

    .has-header-tools & {
      margin-top: 0.25rem;
    }
  }
}

.Notification-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Notification {
  max-width: 20.5rem;
  background: #d4efdf;
  border-radius: var(--border-radius-messages);
  padding: 0.75rem 1rem;
  gap: 1rem;
  display: flex;
  align-items: center;

  // .text-entity-link,
  // .text-entity-link:hover,
  // .text-entity-link:active {
  //   color: #fff !important;
  //   text-decoration: underline;
  // }

  svg {
    flex-shrink: 0;
  }
  &.error {
    background: #fde7eb;
    path {
      stroke: var(--color-error);
    }
  }

  .content {
    word-break: break-word;
    flex-grow: 1;
    line-height: 1.3;
  }

  &.bold-link b {
    color: var(--color-primary);
    cursor: pointer;
  }

  .notification-title {
    font-weight: 500;
  }

  .Notification-button {
    color: var(--color-primary);
    font-weight: 500;
    text-transform: none;
    margin: 0 0.5rem;
    height: 2rem;
    width: auto;
  }
}
