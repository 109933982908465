.root {
  padding: 0.75rem 0 0.5rem;
}

.description {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 1rem;
}
.checkout-picture {
  height: 6rem;

  @supports (aspect-ratio: 1) {
    margin: 0.25rem 0.75rem 0 0;
    min-width: 0;
    width: 100%;
    max-width: 8rem;
    height: auto;
  }
}

.text {
  min-width: 0;
}

.checkout-title {
  font-size: 1.125rem;
  line-height: 1.25;
}

.checkout-description {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: var(--color-text-secondary);
  word-break: break-word;
  margin-bottom: 0.25rem;
}

.price-info {
  margin: 1rem;
}

.price-info-item {
  margin: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  color: var(--color-text-secondary);
  font-weight: 500;

  &-main {
    color: var(--color-text);
  }

  &-title {
    display: flex;
    flex: 1 auto;
  }
}

.tipsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem
}

.tipsItem {
  border-radius: 1.375rem;
  padding: 0 0.75rem;
  height: 2.5rem;
  min-width: 5rem;
  line-height: 2.5rem;
  text-align: center;
  background: var(--color-primary-opacity);
  color: var(--color-primary);
  transition: background-color 200ms, color 200ms;
  cursor: pointer;
  font-weight: 500;

  &:hover,
  &:focus {
    background: var(--color-primary-opacity-hover);
  }

  &_active {
    color: var(--color-white);
    background: var(--color-primary) !important;
  }

  :global(.theme-dark) & {
    color: var(--color-white);
  }
}

.invoice-info {
  border-top: 0.0625rem var(--color-borders) solid;
  padding: 0.5rem;
}

.provider {
  float: left;
  background: no-repeat center;
  background-size: 2rem;
  border-radius: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 2rem;
}

.provider.stripe {
  background-image: url("../../assets/stripe-logo.png");
}

.provider.smartglocal {
  background-image: url("../../assets/smartglocal-logo.png");
}

.checkout-info-item-info {
  &-title {
    font-size: 1rem;
    line-height: 1.4375rem;
    margin-bottom: 0;
    font-weight: 400;
    word-break: break-word;
    color: var(--color-text);
    text-transform: capitalize;
  }

  &-data {
    margin-bottom: 0;
    font-size: 0.875rem;
    color: var(--color-text-secondary);
  }
}

.tos-checkbox {
  padding-left: 4rem;

  :global(.Checkbox-main) {
    &::before,
    &::after {
      left: 0.6875rem;
    }
  }
}
