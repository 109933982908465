.StickerButton {
  --custom-emoji-size: 2.5rem;
  --premium-gradient: linear-gradient(88.39deg, #6C93FF -2.56%, #976FFF 51.27%, #DF69D1 107.39%);

  display: inline-block;
  width: 4rem;
  height: 4rem;
  margin: 0.5rem;
  border-radius: var(--border-radius-messages-small);
  background: transparent no-repeat center;
  background-size: contain;
  transition: background-color 0.15s ease, opacity 0.3s ease !important;
  position: relative;

  &.custom-emoji {
    width: var(--custom-emoji-size);
    height: var(--custom-emoji-size);
    margin: 0.3125rem;

    &.status-default {
      font-size: 2rem;

      padding: 0.25rem;
      color: var(--color-text);
    }
  }

  &.set-expand {
    padding: 0;
    vertical-align: bottom;
  }

  .sticker-locked {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    background: var(--premium-gradient);
  }

  .sticker-premium {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1.25rem;
    height: 1.25rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    color: white;
    background: var(--premium-gradient);

    z-index: 1;
  }

  &.interactive {
    cursor: pointer;

    &:hover {
      background-color: var(--color-interactive-element-hover);

      .sticker-remove-button {
        opacity: 1;
      }
    }
  }

  @media (max-width: 600px) {
    &, &.custom-emoji {
      margin: 0.25rem;
    }
  }

  &.set-button {
    width: 2.75rem !important;
    height: 2.75rem;
    margin: 0 0.5rem;
  }

  &.large {
    width: 10rem;
    height: 10rem;
    margin: 0;
  }

  .AnimatedSticker,
  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img,
  video {
    object-fit: contain;
    -webkit-touch-callout: none;
    user-select: none;
  }

  .sticker-remove-button {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.125rem;

    i {
      font-size: 1rem;
    }

    opacity: 0;
  }
}

.sticker-context-menu {
  position: absolute;
  cursor: default;
  z-index: var(--z-header-menu);

  .bubble {
    width: auto !important;
  }
}
