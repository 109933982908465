
@keyframes bar-animation-transform-1 {
  0%   { transform: scaleY(0.33); }
  12.5%  { transform: scaleY(1.66); }
  25%  { transform: scaleY(0.33); }
  37.5%  { transform: scaleY(1); }
  50%  { transform: scaleY(0.33); }
  62.5%  { transform: scaleY(1.66); }
  75%  { transform: scaleY(0.33); }
  87.5% { transform: scaleY(1.66); }
  100%   { transform: scaleY(0.33); }
}

@keyframes bar-animation-transform-2 {
  0%   { transform: scaleY(1); }
  12.5%  { transform: scaleY(0.33); }
  25%  { transform: scaleY(1.66); }
  37.5%  { transform: scaleY(0.33); }
  50%  { transform: scaleY(1); }
  62.5%  { transform: scaleY(0.33); }
  75%  { transform: scaleY(1.66); }
  87.5% { transform: scaleY(0.33); }
  100%   { transform: scaleY(1); }
}


.ChatCallStatus {
  position: absolute;
  right: 6px;
  bottom: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #0ac630;
  border: 2px solid var(--color-background);
  overflow: hidden;
  z-index: 1;

  .indicator {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    & > div {
      width: 2px;
      height: 6px;
      background: var(--color-background);
      border-radius: 1px;
      margin: 1px;
      will-change: transform;
      transform: translateZ(0);
    }
    & > div:nth-child(odd) {
      transform: scaleY(0.8);
    }
    & > div:nth-child(even) {
      transform: scaleY(1.33);
    }
  }

  &.selected {
    background-color: var(--color-white);
    border-color: var(--color-chat-active);
    .indicator div{
      background-color: var(--color-chat-active);
    }
  }

  &.active .indicator {
    div:nth-child(odd) {
      animation: bar-animation-transform-2 3.2s normal infinite;
    }

    div:nth-child(even) {
      animation: bar-animation-transform-1 3.2s normal infinite;
    }
  }

}
