.ChatOrUserPicker {
  z-index: calc(var(--z-media-viewer) + 1);

  .modal-dialog {
    display: flex;
    flex-direction: column;
    height: 70%;
    max-height: 32.5rem;
    max-width: 25rem;

    @media (max-width: 600px) {
      height: 90%;
    }
  }

  .modal-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem 1rem 2.5rem;

    .input-group,
    .input {
      margin: 0;
      flex: 1;
    }

    .form-control {
      border: none;
      box-shadow: none !important;

      height: 2.75rem;
      padding: 0.5rem;
      line-height: 1.75rem;
      unicode-bidi: plaintext;
    }
  }

  .modal-content {
    padding: 0 0 0.5rem !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > .Transition {
      height: 100%;
      overflow: hidden;
    }

    .picker-list {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 0.5rem;
    }
  }

  .no-results {
    height: 100%;
    margin: 0;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-secondary);
  }

  .scroll-container {
    position: relative;
  }

  .ListItem {
    position: absolute;
    width: 100%;

    body.is-ios &:not(:last-of-type)::after,
    body.is-android &:not(:last-of-type)::after {
      right: -0.5rem;
    }
  }

  .ListItem.chat-item-clickable {
    &:not(.force-rounded-corners) {
      @media (max-width: 600px) {
        margin: 0;

        .ListItem-button {
          border-radius: 0;
        }
      }
    }
  }

  .topic-icon {
    --custom-emoji-size: 2.75rem;

    margin-inline-end: 0.25rem !important;
    width: 2.75rem;
    height: 2.75rem;
    font-size: 2.75rem !important;
  }

  .topic-icon-letter {
    font-size: 1.5rem;
  }

  .topic-item {
    .ListItem-button {
      display: flex;
      align-items: center;

      font-size: 1rem;
      line-height: 1.6875rem;
      font-weight: 500;
    }

    .fullName {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .emoji-small {
      width: 1rem;
      height: 1rem;
    }
  }
}
