.typing-status {
  display: flex;
  align-items: baseline;

  .sender-name {
    &::after {
      content: '\00a0';
      color: var(--color-text-secondary);
    }
  }
}
