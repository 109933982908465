.top-up {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eeeeef;
    border-radius: 12px;
    width: 149px;
    height: 149px;
    transition: transform 0.25s;
    @media only screen and (max-width: 480px) {
      width: 90px;
      height: 90px;
      svg {
        width: 70px;
      }
    }
    &:not(.not-hover) {
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
    &.not-hover {
      [stroke] {
        stroke: #b1b1b5;
      }
    }
  }

  &__title {
    margin-top: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #070708;
    text-align: center;
  }
}
