.ConfirmPayment {
  display: flex;
  height: 100%;
  border-bottom-left-radius: var(--border-radius-default-small);
  border-bottom-right-radius: var(--border-radius-default-small);
  overflow: hidden;

  &__content {
    width: 100%;
    height: 100%;
    border: none;
  }
}
