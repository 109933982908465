.root {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 1rem;

  :global(.Button.pill) {
    max-width: 100%;
    margin-top: 0.625rem;
    font-weight: 500;
    padding-inline-start: 0.75rem;
    unicode-bidi: plaintext;

    justify-content: start;

    .button-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    i {
      margin-inline-end: 0.625rem;
      font-size: 1.5rem;
    }
  }
}

.sticker {
  height: 7rem;
  margin-bottom: 1.875rem;
}

.title {
  font-size: 1.25rem;
  margin-bottom: 0.125rem;
  word-break: break-word;
  text-align: center;
  max-width: 100%;
}

.description {
  font-size: 0.875rem;
  color: var(--color-text-secondary);
  text-align: center;

  body.is-ios &,
  body.is-macos & {
    color: var(--color-text-secondary-apple);
  }
}
