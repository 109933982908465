.avatar {
  width: 2rem;
  height: 2rem;
  margin-inline-end: 1.5rem;

  border-radius: 0.5rem;
  :global(.Avatar__media) {
    border-radius: 0.5rem;
  }
}

.clear-help {
  margin-top: 0.5rem !important;
  margin-bottom: 0 !important;
}

:global(.subtitle) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
