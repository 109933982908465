@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon.woff2?n9djnk') format('woff2'),
    url('../assets/fonts/icomoon.woff?n9djnk') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-char-'],
[class*=' icon-char-'] {
  &::before {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    content: attr(data-char);
    width: 1.5rem;
    text-align: center;
    display: block;
  }
}

.icon-svg {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &.color-text {
    path {
      stroke: var(--color-text) !important;
    }
  }
  &.destructive {
    path {
      stroke: var(--color-error) !important;
    }
  }
}

.icon-loop:before {
  content: '\e98c';
}
.icon-skip-next:before {
  content: '\e98d';
}
.icon-skip-previous:before {
  content: '\e98e';
}
.icon-volume-1:before {
  content: '\e98f';
}
.icon-volume-2:before {
  content: '\e990';
}
.icon-volume-3:before {
  content: '\e991';
}
.icon-pinned-message:before {
  content: '\e9bf';
}
.icon-archive-filled:before {
  content: '\e9ba';
}
.icon-archive-from-main:before {
  content: '\e9bb';
}
.icon-archive-to-main:before {
  content: '\e9bc';
}
.icon-collapse:before {
  content: '\e9bd';
}
.icon-expand:before {
  content: '\e9be';
}
.icon-replies:before {
  content: '\e9b9';
}
.icon-forums:before {
  content: '\e9b4';
}
.icon-hashtag:before {
  content: '\e9b1';
}
.icon-reopen-topic:before {
  content: '\e9b2';
}
.icon-close-topic:before {
  content: '\e9b3';
}
.icon-open-in-new-tab:before {
  content: '\e9af';
}
.icon-pip:before {
  content: '\e9ae';
}
.icon-gift:before {
  content: '\e9ad';
}
.icon-sort:before {
  content: '\e9ac';
}
.icon-web:before {
  content: '\e9ab';
}
.icon-transcribe:before {
  content: '\e9aa';
}
.icon-add-one-badge:before {
  content: '\e99b';
}
.icon-chat-badge:before {
  content: '\e9a0';
}
.icon-chats-badge:before {
  content: '\e9a1';
}
.icon-double-badge:before {
  content: '\e9a2';
}
.icon-file-badge:before {
  content: '\e9a3';
}
.icon-folder-badge:before {
  content: '\e9a4';
}
.icon-link-badge:before {
  content: '\e9a5';
}
.icon-pin-badge:before {
  content: '\e9a6';
}
.icon-premium:before {
  content: '\e9a7';
}
.icon-unlock-badge:before {
  content: '\e9a8';
}
.icon-lock-badge:before {
  content: '\e9a9';
}
.icon-key:before {
  content: '\e99a';
}
.icon-heart-outline:before {
  content: '\e99e';
}
.icon-heart:before {
  content: '\e99f';
}
.icon-word-wrap:before {
  content: '\e99d';
}
.icon-webapp:before {
  content: '\e993';
}
.icon-reload:before {
  content: '\e994';
}
.icon-install:before {
  content: '\e999';
}
.icon-favorite-filled:before {
  content: '\e998';
}
.icon-share-screen:before {
  content: '\e97a';
}
.icon-video-outlined:before {
  content: '\e997';
}
.icon-stats:before {
  content: '\e996';
}
.icon-copy-media:before {
  content: '\e995';
}
.icon-sidebar:before {
  content: '\e992';
}
.icon-video-stop:before {
  content: '\e98b';
}
.icon-speaker:before {
  content: '\e981';
}
.icon-speaker-outline:before {
  content: '\e982';
}
.icon-phone-discard-outline:before {
  content: '\e983';
}
.icon-allow-speak:before {
  content: '\e984';
}
.icon-stop-raising-hand:before {
  content: '\e985';
}
.icon-share-screen-outlined:before {
  content: '\e986';
}
.icon-voice-chat:before {
  content: '\e987';
}
.icon-video:before {
  content: '\e988';
}
.icon-noise-suppression:before {
  content: '\e989';
}
.icon-phone-discard:before {
  content: '\e98a';
}
.icon-bot-commands-filled:before {
  content: '\e97f';
}
.icon-reply-filled:before {
  content: '\e980';
}
.icon-bug:before {
  content: '\e97e';
}
.icon-data:before {
  content: '\e97d';
}
.icon-darkmode:before {
  content: '\e979';
}
.icon-animations:before {
  content: '\e99c';
}
.icon-enter:before {
  content: '\e97b';
}
.icon-fontsize:before {
  content: '\e97c';
}
.icon-permissions:before {
  content: '\e976';
}
.icon-card:before {
  content: '\e977';
}
.icon-truck:before {
  content: '\e978';
}
.icon-share-filled:before {
  content: '\e95a';
}
.icon-bold:before {
  content: '\e961';
}
.icon-bot-command:before {
  content: '\e962';
}
.icon-calendar-filter:before {
  content: '\e963';
}
.icon-comments:before {
  content: '\e964';
}
.icon-comments-sticker:before {
  content: '\e965';
}
.icon-arrow-down:before {
  content: '\e966';
}
.icon-email:before {
  content: '\e967';
}
.icon-italic:before {
  content: '\e968';
}
.icon-link:before {
  content: '\e969';
}
.icon-link-broken:before {
  content: '\e9b0';
}
.icon-mention:before {
  content: '\e96a';
}
.icon-monospace:before {
  content: '\e96b';
}
.icon-next:before {
  content: '\e96c';
}
.icon-password-off:before {
  content: '\e96d';
}
.icon-pin-list:before {
  content: '\e96e';
}
.icon-previous:before {
  content: '\e96f';
}
.icon-replace:before {
  content: '\e970';
}
.icon-schedule:before {
  content: '\e971';
}
.icon-strikethrough:before {
  content: '\e972';
}
.icon-underlined:before {
  content: '\e973';
}
.icon-zoom-in:before {
  content: '\e974';
}
.icon-zoom-out:before {
  content: '\e975';
}
.icon-spoiler-disable:before {
  content: '\e9b5';
}
.icon-grouped:before {
  content: '\e9b6';
}
.icon-grouped-disable:before {
  content: '\e9b7';
}
.icon-spoiler:before {
  content: '\e9b8';
}
.icon-select:before {
  content: '\e960';
}
.icon-folder:before {
  content: '\e913';
}
.icon-bots:before {
  content: '\e915';
}
.icon-calendar:before {
  content: '\e916';
}
.icon-cloud-download:before {
  content: '\e917';
}
.icon-colorize:before {
  content: '\e918';
}
.icon-forward:before {
  content: '\e927';
}
.icon-reply:before {
  content: '\e947';
}
.icon-help:before {
  content: '\e92a';
}
.icon-info:before {
  content: '\e92b';
}
.icon-info-filled:before {
  content: '\e91b';
}
.icon-delete-filled:before {
  content: '\e91c';
}
.icon-delete:before {
  content: '\e91d';
}
.icon-edit:before {
  content: '\e923';
}
.icon-new-chat-filled:before {
  content: '\e939';
}
.icon-send:before {
  content: '\e94a';
}
.icon-send-outline:before {
  content: '\e94b';
}
.icon-add-user-filled:before {
  content: '\e904';
}
.icon-add-user:before {
  content: '\e905';
}
.icon-delete-user:before {
  content: '\e91e';
}
.icon-microphone:before {
  content: '\e935';
}
.icon-microphone-alt:before {
  content: '\e93b';
}
.icon-poll:before {
  content: '\e938';
}
.icon-revote:before {
  content: '\e93a';
}
.icon-photo:before {
  content: '\e940';
}
.icon-document:before {
  content: '\e91f';
}
.icon-camera:before {
  content: '\e90e';
}
.icon-camera-add:before {
  content: '\e90f';
}
.icon-logout:before {
  content: '\e932';
}
.icon-saved-messages:before {
  content: '\e948';
}
.icon-settings:before {
  content: '\e94e';
}
.icon-phone:before {
  content: '\e93f';
}
.icon-attach:before {
  content: '\e909';
}
.icon-copy:before {
  content: '\e91a';
}
.icon-channel:before {
  content: '\e911';
}
.icon-group:before {
  content: '\e929';
}
.icon-user:before {
  content: '\e959';
}
.icon-non-contacts:before {
  content: '\e928';
}
.icon-active-sessions:before {
  content: '\e902';
}
.icon-admin:before {
  content: '\e906';
}
.icon-download:before {
  content: '\e921';
}
.icon-location:before {
  content: '\e930';
}
.icon-stop:before {
  content: '\e952';
}
.icon-archive:before {
  content: '\e908';
}
.icon-unarchive:before {
  content: '\e953';
}
.icon-readchats:before {
  content: '\e933';
}
.icon-unread:before {
  content: '\e957';
}
.icon-message:before {
  content: '\e934';
}
.icon-lock:before {
  content: '\e931';
}
.icon-unlock:before {
  content: '\e954';
}
.icon-mute:before {
  content: '\e937';
}
.icon-unmute:before {
  content: '\e955';
}
.icon-pin:before {
  content: '\e941';
}
.icon-unpin:before {
  content: '\e956';
}
.icon-smallscreen:before {
  content: '\e95e';
}
.icon-fullscreen:before {
  content: '\e95f';
}
.icon-large-pause:before {
  content: '\e92e';
}
.icon-large-play:before {
  content: '\e92f';
}
.icon-pause:before {
  content: '\e93d';
}
.icon-play:before {
  content: '\e943';
}
.icon-channelviews:before {
  content: '\e912';
}
.icon-message-succeeded:before {
  content: '\e900';
}
.icon-message-read:before {
  content: '\e901';
}
.icon-message-pending:before {
  content: '\e94c';
}
.icon-message-failed:before {
  content: '\e94d';
}
.icon-favorite:before {
  content: '\e93e';
}
.icon-keyboard:before {
  content: '\e944';
}
.icon-delete-left:before {
  content: '\e945';
}
.icon-recent:before {
  content: '\e946';
}
.icon-gifs:before {
  content: '\e94f';
}
.icon-stickers:before {
  content: '\e95b';
}
.icon-smile:before {
  content: '\e950';
}
.icon-animals:before {
  content: '\e907';
}
.icon-eats:before {
  content: '\e922';
}
.icon-sport:before {
  content: '\e951';
}
.icon-car:before {
  content: '\e910';
}
.icon-lamp:before {
  content: '\e92c';
}
.icon-language:before {
  content: '\e92d';
}
.icon-flag:before {
  content: '\e926';
}
.icon-more:before {
  content: '\e936';
}
.icon-search:before {
  content: '\e949';
}
.icon-remove:before {
  content: '\e95c';
}
.icon-add:before {
  content: '\e903';
}
.icon-check:before {
  content: '\e914';
}
.icon-close:before {
  content: '\e919';
}
.icon-arrow-left:before {
  content: '\e90d';
}
.icon-arrow-right:before {
  content: '\e93c';
}
.icon-down:before {
  content: '\e920';
}
.icon-up:before {
  content: '\e958';
}
.icon-eye-closed:before {
  content: '\e925';
}
.icon-eye:before {
  content: '\e924';
}
.icon-muted:before {
  content: '\e95d';
}
.icon-avatar-archived-chats:before {
  content: '\e90a';
}
.icon-avatar-deleted-account:before {
  content: '\e90b';
}
.icon-avatar-saved-messages:before {
  content: '\e90c';
}
.icon-pinned-chat:before {
  content: '\e942';
}
