.StickerPicker {
  height: 100%;

  &-main {
    position: relative;
    height: calc(100% - 3rem);
    overflow-y: auto;
    padding: 0.5rem 0.25rem;
  }

  &-header {
    height: 3rem;
    border-bottom: 1px solid var(--color-borders);
    padding: 0.125rem 0;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    box-shadow: 0 0 2px var(--color-default-shadow);

    scrollbar-width: none;
    scrollbar-color: rgba(0, 0, 0, 0);

    &::-webkit-scrollbar {
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
    }

    // Spacer to counter last button margin not being accounted in scroll width
    &::after {
      content: "";
      display: block;
      flex-shrink: 0;
      width: 1px;
      height: 1rem;
    }


    .sticker-set-button {
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;

      &.StickerButton {
        background-size: 2rem;

        video, img, .AnimatedSticker {
          top: 0.375rem;
          left: 0.375rem;
          width: 2rem;
          height: 2rem;
        }
      }

      .sticker-set-cover {
        width: 2rem;
        height: 2rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        video, img, .AnimatedSticker {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.activated {
        background-color: var(--color-background-selected);
      }
    }

    .shared-canvas-container {
      display: inline-block;
    }

    .shared-canvas {
      max-width: 1280px; // STICKER_PICKER_MAX_SHARED_COVERS * (STICKER_SIZE_PICKER_HEADER + 10 * 2)
      z-index: 1;
    }
  }

  .symbol-set-container {
    width: 100%;
    line-height: 0;
  }

  .sticker-set-button {
    width: 2.75rem !important;
    height: 2.75rem;
    margin: 0 0.25rem;
    border-radius: var(--border-radius-messages-small);
  }

  .picker-disabled {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.CustomEmojiPicker {
  --emoji-size: 2.5rem;
}
