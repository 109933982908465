.root {
  display: flex;
  position: relative;
}

.floating-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  background: #7E85FF;
  position: absolute;
  top: -1.5rem;
  left: 50%;
  transform: translate(-50%, -20px);
}

.floating-badge-triangle {
  position: absolute;
  bottom: -15px;
}

.floating-badge-icon {
  margin-right: 0.25rem;
}

.text, .floating-badge-value {
  font-size: 16px;
  font-weight: 500;
}

.left-text {
  composes: text;
}

.right-text, .left-text {
  composes: text;
  margin-inline-end: auto;
}

.right-value, .left-value {
  composes: text;
  margin-inline-start: 1rem;
}

.right-text, .right-value {
  composes: text;
  color: #ffffff;
}

.left-text, .left-value {
  color: #000000;
}

.line {
  padding: 0.375rem 0.75rem;
  flex-basis: 50%;
  display: flex;
}

.left {
  background: #F1F3F5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(84.4deg, #6C93FF -4.85%, #976FFF 51.72%, #DF69D1 110.7%), #F1F3F5;
}

