.root :global(.modal-content) {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.root :global(.modal-dialog) {
  max-width: 22.5rem;
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
}

.limit-compare {
  margin: 0.5rem 0 1.25rem 0;
  width: 100%;
}

.picker-list {
  overflow-y: auto;
  margin-bottom: 0.5rem;
  & :global(.title) {
    line-height: 1.25;
  }
  & :global(.status) {
    line-height: 1.25;
  }
}

.limit-badge {
  background: var(--color-primary);
  padding: 0.8125rem 1.5rem;
  border-radius: 1.5rem;
  margin: 0.5rem auto 1rem auto;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.limit-value {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--color-white);
}

.limit-icon {
  display: flex;
  font-size: 1.25rem;
  color: var(--color-white);
  [stroke] {
    stroke: white;
  }
  g {
    [fill] {
      fill: white;
    }
  }
}

.limit-description {
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.28;
  margin-bottom: 1rem;
}

.subscribe-button {
  margin-left: 1rem !important;
  --premium-gradient: linear-gradient(
    88.39deg,
    #6c93ff -2.56%,
    #976fff 51.27%,
    #df69d1 107.39%
  );
  background: var(--premium-gradient);
}

.button-icon {
  margin-left: 0.75rem;
  font-size: 1.25rem;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.floating-badge {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #ffffff;
  padding: 0.685rem;
  border-radius: 0.75rem;
  background: linear-gradient(135deg, #6ca5f9 27.08%, #3a85f3 75.52%);
  &:before {
    content: '';
    display: block;
    border-width: 6px 6px 0 6px;
    border-style: solid;
    border-color: #3c86f3 transparent transparent;
    position: absolute;
    top: 100%;
  }
}

.floating-badge-triangle {
  position: absolute;
  bottom: -15px;
}

.floating-badge-icon {
  margin-right: 0.25rem;
}

.text,
.floating-badge-value {
  font-size: 15px;
  font-weight: 500;
}

.right-value,
.left-value {
  composes: text;
  margin-inline-start: 1rem;
}

.right-text,
.right-value {
  composes: text;
  color: #ffffff;
}

.line {
  padding: 0.375rem 0.75rem;
  flex-basis: 50%;
  display: flex;
}

.left {
  display: flex;
  justify-content: center;
  background: #f1f3f5;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
  font-size: 15px;
  b {
    font-weight: 500;
    margin-inline-end: 0.5rem;
  }
  span {
    font-weight: 500;
    color: var(--color-error);
  }
}

.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(
      84.4deg,
      #6c93ff -4.85%,
      #976fff 51.72%,
      #df69d1 110.7%
    ),
    #f1f3f5;
}
