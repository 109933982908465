$modalHeaderAndFooterHeight: 8.375rem;

.PaymentModal {
  &.recurring {
    .Transition {
      height: 33rem;
      max-height: calc(92vh - $modalHeaderAndFooterHeight);
    }
  }

  .modal-backdrop {
    pointer-events: none;
  }

  .header {
    position: relative;
    border-top-left-radius: var(--border-radius-default-small);
    border-top-right-radius: var(--border-radius-default-small);
    width: 100%;
    padding: 0.5rem 1rem 0.25rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: var(--color-background);

    h3 {
      margin-bottom: 0;
      margin-left: 1.5rem;
      unicode-bidi: plaintext;
      text-align: initial;
    }
  }

  .Transition {
    height: min(27rem, 68vh);
  }

  .empty-content {
    height: 25rem;
    max-height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .receipt-content {
    height: 25rem;
    overflow-y: auto;
  }

  .content {
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .footer {
    position: relative;
    bottom: 0;
    border-bottom-left-radius: var(--border-radius-default-small);
    border-bottom-right-radius: var(--border-radius-default-small);
    width: 100%;
    padding: 0.75rem 1rem;
    background: var(--color-background);
    border-top: 0.0625rem var(--color-borders) solid;

    button {
      text-transform: none;
      font-weight: 500;
    }
  }

  .modal-dialog {
    width: 25rem;
  }

  .modal-content {
    padding: 0;
    overflow: hidden;
  }
}

@media screen and (max-device-width: 640px) and (max-height: 640px) and (orientation: landscape) {
  .PaymentModal,
  .PaymentModal.recurring {
    .modal-dialog {
      max-height: 100%;
    }

    .Transition {
      height: 10rem;
    }
  }
}
