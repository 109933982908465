@import 'reboot';
@import 'variables';
@import 'mixins';
@import 'spacing';
@import 'forms';
@import 'icons';
@import 'common';
@import '../assets/fonts/roboto.css';
@import './print';

html,
body {
  width: 100%;
  height: 100vh;
  //background-color: #5999f7;
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: var(--color-text);
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --font-family: 'Roboto', -apple-system, BlinkMacSystemFont,
    'Apple Color Emoji', 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-family-monospace: 'Cascadia Mono', 'Roboto Mono', 'Droid Sans Mono',
    'SF Mono', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;

  @media (max-width: 600px) {
    height: calc(var(--vh, 1vh) * 100);
  }
}

noscript {
  width: 100%;
  height: 100%;
  display: grid;
  align-content: center;
  justify-items: center;
  .nojs-video {
    pointer-events: none;
    &::-webkit-media-controls {
      display: none;
    }
  }
}

body.is-ios,
body.is-macos {
  --font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Roboto',
    'Apple Color Emoji', 'Helvetica Neue', sans-serif;
}

html[lang='fa'],
html[lang='fa'] body {
  --font-family: 'Vazirmatn', 'Roboto', -apple-system, BlinkMacSystemFont,
    'Apple Color Emoji', 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

  &.is-ios,
  &.is-macos {
    --font-family: 'Vazirmatn', -apple-system, BlinkMacSystemFont, 'Roboto',
      'Apple Color Emoji', 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }
}

body.is-ios {
  --border-radius-messages: 1rem;
  --border-radius-messages-small: 0.5rem;
}

body.cursor-grabbing,
body.cursor-grabbing * {
  cursor: grabbing !important;
}

body.cursor-ew-resize {
  cursor: ew-resize !important;
}

#root,
.full-height {
  height: 100%;

  @media (max-width: 600px) {
    height: calc(var(--vh, 1vh) * 100);
  }

  &.is-auth {
    background: var(--color-background);
  }
}

#middle-column-portals,
#portals {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
}

.hidden {
  visibility: hidden;
}

.no-selection {
  user-select: none;
  -webkit-user-select: none !important;
}

.resize-handle {
  display: none;
  position: absolute;
  top: 0;
  right: -0.25rem;
  bottom: 0;
  width: 0.25rem;
  z-index: var(--z-resize-handle);
  cursor: ew-resize;

  @media (min-width: 926px) {
    display: block;
  }
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

/*
 See the article for more information on this visually-hidden pattern.
 https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*/
.visually-hidden {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  z-index: -1;
}

.not-implemented {
  opacity: 0.5;

  &,
  & * {
    cursor: not-allowed !important;
  }
}

* {
  box-sizing: border-box;
}

.no-animations #root *,
.no-animations #root *::before,
.no-animations #root *::after {
  transition: none !important;
}

.custom-scroll,
.custom-scroll-x {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: scrollbar-color 0.3s ease;

  //-webkit-overflow-scrolling: touch;
  // Fix scroll lock on iOS
  pointer-events: auto;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0.375rem;
    // `box-shadow` prevents repaint on macOS when hovering out of scrollable container
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.01);
  }

  &:hover,
  &:focus,
  &:focus-within,
  &.visible-scroll {
    scrollbar-color: var(--color-scrollbar) transparent;

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-scrollbar);
    }
  }
}

body:not(.is-ios) {
  .custom-scroll {
    &::-webkit-scrollbar {
      width: 0.375rem;
    }
  }

  .custom-scroll-x {
    &::-webkit-scrollbar {
      height: 0.375rem;
    }
  }
}

.no-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.emoji-small {
  background: no-repeat;
  background-size: 1.25rem;
  color: transparent;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  margin-inline-end: 1px;
  overflow: hidden;
  flex-shrink: 0;

  &::selection {
    background-color: var(--color-selection-highlight-emoji);
    color: transparent;
  }
}

[role='button'] {
  outline: none !important;
  cursor: pointer;
}

.zoom-in {
  transform: scale(0.85);
}

.opacity-transition {
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.2, 0, 0.2, 1);

  &.open {
    opacity: 1;
    transition: all 0.2s ease-in;
    &.zoom-in {
      transform: scale(1);
    }
  }

  &:not(.shown) {
    display: none;

    &.accordion-collapse {
      display: block;
      height: 0;
      visibility: hidden;
    }
  }

  &.slow {
    transition-duration: 0.3s;
  }
}

.width-in {
  max-width: 0;
  &.open {
    max-width: 100%;
  }
}

.zoom-in-enter {
  opacity: 0;
  transform: scale(0);
}
.zoom-in-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.15s ease,
    transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.zoom-in-exit {
  opacity: 1;
  transform: scale(1);
}
.zoom-in-exit-active {
  opacity: 0;
  transform: scale(0);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.color-primary {
  color: var(--color-primary) !important;
}

.color-danger {
  color: var(--color-error) !important;
}

.text-muted {
  color: var(--color-text-secondary) !important;
}

.protector {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  user-select: none;
  -webkit-touch-callout: none;
}

.for-ios-autocapitalization-fix {
  position: fixed;
  font-size: 16px;
  opacity: 0;
  bottom: 1rem;
  z-index: -1;
}

.emoji-test-element {
  opacity: 0;
  display: inline;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  left: 0;
}

.shared-canvas-container {
  position: relative;
}

.shared-canvas,
.absolute-video-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@keyframes grow-icon {
  0% {
    transform: scale(0.5);
    opacity: 0.8;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes hide-icon {
  from {
    transform: scale(1);
    opacity: 0.4;
  }

  to {
    transform: scale(0.5);
    opacity: 0;
  }
}
