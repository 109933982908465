.wallet {
  &__info-channel-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 39px 0 20px;
  }

  &__info-channel-title-wrap {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}