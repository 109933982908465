@import '../../styles/mixins';

@mixin thumb-styles() {
  background: var(--slider-color);
  border: none;
  height: 0.6875rem;
  width: 0.6875rem;
  border-radius: 50%;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
}

.RangeSlider {
  --slider-color: var(--color-primary);

  margin-bottom: 1rem;

  &.disabled {
    pointer-events: none;
    --slider-color: var(--color-text-secondary);
  }

  .slider-top-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;

    .value {
      flex-shrink: 0;
      margin-left: 1rem;
      color: var(--color-text-secondary);
    }

    &[dir='rtl'] {
      .value {
        margin-left: 0;
        margin-right: 1rem;
      }
    }
  }

  .slider-main {
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0.3125rem;
      height: 0.125rem;
      background-color: var(--color-borders);
      border-radius: 0.125rem;
      opacity: 0.5;
      z-index: -1;
    }
  }

  .slider-fill-track {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0.3125rem;
    height: 0.125rem;
    background-color: var(--slider-color);
    border-radius: 0.125rem;
    pointer-events: none;
  }

  &.bold {
    .slider-main::before {
      top: 0.25rem;
      height: 0.25rem;
    }

    .slider-fill-track {
      top: 0.25rem;
      height: 0.25rem;
    }
  }

  // Reset range input browser styles
  @include reset-range();

  // Apply custom styles
  input[type='range'] {
    // Note that while we're repeating code here, that's necessary as you can't comma-separate these type of selectors.
    // Browsers will drop the entire selector if it doesn't understand a part of it.
    &::-webkit-slider-thumb {
      @include thumb-styles();
    }

    &::-moz-range-thumb {
      @include thumb-styles();
    }

    &::-ms-thumb {
      @include thumb-styles();
    }
  }

  .slider-options {
    display: grid;
    grid-template-rows: auto;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }

  &.thumbs-4 {
    .slider-options {
      grid-auto-columns: 17% 1fr 1fr 17%;
    }
  }

  &.thumbs-5 {
    .slider-options {
      grid-auto-columns: 13.5% 1fr 1fr 1fr 13.5%;
    }
  }

  .slider-option {
    font-size: 0.8125rem;
    text-align: center;
    cursor: pointer;
    padding-bottom: 0.625rem;
    color: var(--color-text-secondary);
    transition: color 0.2s ease;

    &:hover,
    &.active {
      color: var(--color-text);
    }

    &.active {
      font-weight: 500;
    }

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}
