:root {
  --thumbs-background: var(--color-background);
}

.thumb {
  width: 100%;
  height: 100%;
  z-index: 1;

  &:global(.closing) {
    transition-delay: 150ms;
  }
}

.thumb-opaque {
  background: var(--thumbs-background);
  transition-delay: 0s;
}

.no-transition {
  transition: none !important;
}

.media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.thumb, .media {
  // @optimization
  &:not(:global(.shown)) {
    display: block !important;
  }
}
