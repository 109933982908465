.NothingFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  &.with-description {
    flex-direction: column;
  }

  .AnimatedSticker {
    margin: 0 auto;
  }

  p {
    color: var(--color-text-secondary);
  }

  .description {
    color: var(--color-text-secondary);
    font-size: 0.875rem;
    text-align: center;
    margin: 1rem 0 0;
    unicode-bidi: plaintext;
  }
}
