.File {
  --secondary-color: var(--color-text-secondary);

  .Message.own & {
    --secondary-color: var(--color-text-green);
  }

  .content-inner & {
    min-width: 14rem;
    @media (max-width: 340px) {
      min-width: 11rem;
    }
  }

  display: flex;
  align-items: center;

  .file-icon-container {
    position: relative;
  }

  .file-progress,
  .file-icon,
  .file-preview {
    width: 3rem;
    height: 3.25rem;
    margin-inline-end: 0.75rem;
    border-radius: var(--border-radius-messages-small);
    background: linear-gradient(180deg, #5895ff 0%, #387cf4 100%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-progress {
    position: absolute;
    top: 0;
    left: 0;
  }

  .file-icon {
    align-items: flex-end;
    flex-shrink: 0;
    position: relative;

    .file-ext {
      font-size: 0.725rem;
      transition: opacity 0.15s;
      color: white;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      border-bottom-left-radius: 0.25rem;
      background: rgba(white, 0.4);
      border-color: transparent var(--color-background) transparent
        var(--color-background);
      border-width: 0 0.85rem 0.85rem 0;
      border-style: solid;
      /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
      transition: border-width 0.15s ease-in;
    }
  }

  .file-preview {
    background: transparent;
    overflow: hidden;

    & + .action-icon,
    & + .file-progress {
      background: rgba(black, 0.5);
      border-radius: var(--border-radius-messages-small);
    }

    img {
      object-fit: cover;
    }
  }

  .action-icon {
    color: #fff;
    font-size: 1.5rem;
    position: absolute;
    top: 0;
    width: 3rem;
    height: 3.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
  }

  &.interactive .file-icon-container {
    cursor: pointer;

    &:hover {
      .file-icon::after {
        border-width: 0;
      }

      .action-icon {
        opacity: 1;

        &.hidden {
          opacity: 0;
        }
      }

      .file-ext {
        opacity: 0;
      }
    }
  }

  .file-info {
    margin-top: 0.1875rem;
    margin-right: 0.125rem;
    white-space: nowrap;
    overflow: hidden;
    flex-grow: 1;

    & + .Link {
      font-size: 0.75rem;
      color: var(--color-text-secondary);
      padding-left: 1rem;
      white-space: nowrap;
    }
  }

  .file-title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;

    .emoji {
      vertical-align: -4px;
    }
  }

  .file-subtitle {
    font-size: 0.875rem;
    line-height: 0.9375rem;
    color: var(--secondary-color);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    span + span {
      &::before {
        content: '\00a0\2022\00a0';
      }
    }
  }

  &.smaller {
    --background-color: var(--color-background);
    --border-radius-messages-small: 0.3125rem;

    .action-icon,
    .file-progress,
    .file-icon,
    .file-preview {
      width: 3rem;
      height: 3rem;
    }

    .file-icon {
      padding-bottom: 0.375rem;

      &::after {
        border-width: 0 1rem 1rem 0;
      }
    }

    .file-info {
      margin-top: -0.125rem;
    }

    .file-title {
      font-weight: 400;
    }

    .file-subtitle {
      line-height: 1rem;
    }
  }

  &:dir(rtl),
  &[dir='rtl'] {
    .file-progress,
    .file-icon,
    .file-preview {
      margin-left: 0.75rem;
      margin-right: 0;
    }

    .file-info {
      text-align: right;

      & + .Link {
        padding-left: 0;
        padding-right: 1rem;
      }
    }

    .action-icon {
      left: auto;
      right: 0;
    }
  }
  &.file-preview-item {
    .file-icon {
      width: 1.5625rem;
      height: 1.875rem;
      padding: 0 0 0.125rem;
      margin: 0;
      &::after {
        border-width: 0 0.625rem 0.625rem 0;
        border-color: transparent var(--color-chat-hover) transparent
          var(--color-chat-hover);
      }
      .file-ext {
        font-size: 0.5rem;
      }
    }
  }
}

// Document extension colors
.File {
  .file-progress,
  .file-icon {
    &.red {
      background: #e94848;
    }

    &.orange {
      background: var(--color-warning);
    }

    &.green {
      background: linear-gradient(180deg, #1abf74 0%, #019f57 100%);
    }
  }
}
