.SettingsGeneralBackground {
  .settings-wallpapers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 10px;
    background-color: var(--color-background);
    box-shadow: inset 0 -0.0625rem 0 0 var(--color-background-secondary-accent);
  }

  .Loading {
    height: auto !important;
    margin-top: 5rem;
  }
}

.appearance {
  display: flex;
  justify-content: center;

  &__wrap {
    margin-top: 56px;
    max-width: 400px;
    width: 100%;
  }

  &__text-size-wrap {
    position: relative;
    padding: 0 20px;
  }

  &__line {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 2px;
    height: 12px;
    background: #0A49A5;
  }

  &__settings-chat {
    margin-top: 22px;
  }

  &__chat {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    label {
      margin-bottom: 0;
    }

    &:first-child {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 1px;
        width: 90%;
        border-top: 1px solid #F7F7F7;
        border-bottom: 1px solid #F7F7F7;
        margin-left: 20px;
      }
    }

    
    &:last-child {
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        height: 1px;
        width: 90%;
        border-top: 1px solid #F7F7F7;
        border-bottom: 1px solid #F7F7F7;
        margin-left: 20px;
      }
    }

    &:hover {
      background: #F7F7F7;
      border-bottom: 1px solid #F7F7F7;
      border-radius: 12px;
      cursor: pointer;
    }
  }
}

.container-background {
  max-width: 720px;
  padding: 0 20px;
  margin: 0 auto;
}

.select-background {
  margin-top: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #929298;
  margin-bottom: 8px;
}

.background-wrap {
  margin-top: 22px;
  margin-left: -20px;
}

.select-background-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
}

.select-background-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0A49A5;
}

.chat-theme-wrap {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
}

.react-toggle-track {
  width: 30px;
  height: 13px;
}

.react-toggle-thumb {
  width: 11px;
  height: 11px;
}

.react-toggle--checked .react-toggle-thumb {
  left: 17px;
  border-color: #0A49A5;
}

.react-toggle--checked .react-toggle-track {
  background-color: #0A49A5;

  &:hover {
    background-color: #0A49A5;
  }
}
