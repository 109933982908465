.settings-folders-recommended-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.settings-folders-blocked-icon {
  color: var(--color-text-secondary);
  margin-left: 0.25rem;
}

.settings-folders-list-item {
  .ChatInfo {
    display: flex;
    align-items: center;

    .Avatar {
      margin-left: -0.25rem;
      margin-right: 1.5rem;
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      h3 {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.3125;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    .status {
      display: none;
    }

    &[dir="rtl"] {
      .title h3 {
        text-align: right;
      }
    }
  }

  .ListItem-button {
    i {
      opacity: 0.9;
    }
  }

  &.color-primary .ListItem-button {
    color: var(--color-primary);

    i {
      opacity: 1;
      color: inherit;
    }
  }

  &[dir="rtl"] {
    .Avatar {
      margin-left: 1.5rem;
      margin-right: -0.25rem;
    }
  }
}

.settings-item .ShowMoreButton {
  margin: 0 -1rem;
  width: calc(100% + 2rem);
  padding-left: 1rem !important;
}

.settings-sortable-container {
  position: relative;
}

.settings-sortable-container .draggable-knob {
  margin-top: -0.25rem;
}

.settings-sortable-item .multiline-item {
  padding-inline-end: 3rem;
}
