.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__btn-wrapper {
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 320px;
    .primary {
      flex-grow: 1;
      min-width: 48%;
    }
  }
  .description {
    text-align: center;
    font-size: 0.8125rem;
    line-height: 1.15;
    max-width: 20rem;
  }
}
.wallet__earn-statistic {
  margin-bottom: 1.625rem;
  .Button {
    flex-direction: column;
    height: 4.625rem;
    border-radius: 0.875rem;
    &.outline {
      border-color: #f2f2f2;
    }
  }
  .title {
    font-size: 0.75rem;
  }
  .row {
    margin: -0.25rem;
    padding: 0 1.25rem;
  }
  .col {
    padding: 0.25rem;
  }
}

.earning-content {
  padding-top: 1rem;
  .earning-reward {
    margin: 0 -0.25rem;
    .col {
      padding: 0.25rem;
    }
    .reward {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1.25rem;
      background-color: var(--color-background-secondary);
      border-radius: var(--border-radius-messages);
      .icon-svg {
        width: 2.1875rem;
        height: 2.1875rem;
        border-radius: 43%;
        background: linear-gradient(135deg, #ffa500 31.17%, #d88b00 75.98%);
      }
      &.total {
        .icon-svg {
          background: linear-gradient(135deg, #44be2e 31.17%, #27ae60 75.98%);
        }
      }
      [stroke] {
        stroke: white;
      }
      .title {
        font-size: 0.6875rem;
        color: var(--color-placeholders);
        margin-bottom: 0.25rem;
      }
      h3 {
        margin: 0;
        line-height: 1;
        font-size: 1.5rem;
      }
    }
  }
  .Avatar {
    --radius: 45%;
    &.size-medium {
      width: 2.625rem;
      height: 2.625rem;
    }
  }
  .earn {
    .row {
      gap: 2rem;
    }
    .col {
      text-align: right;
    }
    .info-row {
      gap: 0.25rem;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.02rem;
      background-color: var(--color-background-secondary);
    }
    .ListItem-button {
      padding: 1.125rem 1.25rem;
    }
  }
}
