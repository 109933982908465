.DeleteChatModal {
  .modal-dialog {
    max-width: 20rem;
  }

  .modal-header {
    padding: 1.125rem 1.25rem 0;
  }

  .modal-title:not(:only-child) {
    margin: 0 0 0 0.75rem;
  }

  .modal-content {
    padding: 0.5rem 1.25rem;
  }

  .confirm-dialog-button {
    margin-right: -0.625rem;
  }
}
